export const loadFromStorage = (key) => {
	try {
		const serializedData = localStorage.getItem(key);
		if (serializedData === null) {
			return undefined;
		}
		return JSON.parse(serializedData);
	} catch (err) {
		return undefined;
	}
}

export const saveToStorage = (key, data) => {
	try {
		const serializedData = JSON.stringify(data);
		localStorage.setItem(key, serializedData);
	} catch (err) {
		console.log(err);
	}
}
