import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './Auth.sass';
import AuthForm from '../auth/AuthForm';
import SignupForm from '../auth/SignupForm';
import RecoveryForm from '../auth/RecoveryForm';

export default () => (
    <div className="Auth">
        <Switch>
            <Route exact path="/auth/signin" component={AuthForm} />
            <Route exact path="/auth/signup" component={SignupForm} />
            <Route exact path="/auth/recover" component={RecoveryForm} />
            <Redirect path="/auth*" to="/auth/signin" />
        </Switch>
    </div>
);
