import React from 'react';
import {
    IconButton,
} from '@material-ui/core';
import {
    ImageOutlined as ImageIcon
} from '@material-ui/icons';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const SendPhotoButton = ({ socket, fromUserId, toUserId, sendMessage }) => {
    const handleUpload = async (event) => {
        const image = event.target.files[0];
        sendMessage(socket, {
            from_user_id: fromUserId,
            to_user_id: toUserId,
            text: '',
            image: await toBase64(image),
        });
    }

    return (
        <div>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="message-photo-upload-button"
                type="file"
                onChange={handleUpload}
            />
            <IconButton size="small" component="label" htmlFor="message-photo-upload-button">
                <ImageIcon />
            </IconButton>
        </div>
    );
}

export default SendPhotoButton;
