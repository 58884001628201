import React, { useEffect } from 'react';
import axios from 'axios';
import {
    AppBar,
    Toolbar,
    Grid,
    Container,
    CircularProgress,
} from '@material-ui/core';

import logoUrl from '../../assets/logo-red.png';

const checkPaymentStatus = async () => {
    try {
        const paymentId = (new URLSearchParams(window.location.search)).get('id');
        const { data } = await axios.post('/api/payments/payment_status/', { uuid4_id: paymentId });
        if (data.status === 'succeeded' || data.status === 'waiting_for_capture') {
            window.location.href = '/payment-success';
        } else {
            window.location.href = '/payment-fail';
        }
    } catch (e) {
        console.log(e);
        // TODO: display error
    }

}

const PaymentProcess = () => {
    useEffect(() => {
        checkPaymentStatus();
        setInterval(checkPaymentStatus, 3000);
    }, []);

    return (
        <div className="Home">
            <AppBar color="inherit" elevation={1} position={"static"}>
                <Container>
                    <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
                        <div />
                        <img src={logoUrl} alt="lovapp.ru" />
                        <div style={{ width: 36 }} />
                    </Toolbar>
                </Container>
            </AppBar>
            <Container>
                <Grid container justify="center" alignItems="center" style={{ height: 'calc(100vh - 64px)' }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default PaymentProcess;
