import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Badge
} from '@material-ui/core';
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { loadChatList, selectChat } from '../../actions/chat';

const ChatList = ({ socket, chats, current, loadChatList, selectChat }) => {
    const classes = useStyles();
    useEffect(() => {
        if (!chats) loadChatList();
    }, [chats, loadChatList]);

    if (!chats) return null;
    if (chats.length === 0) return (
        <List style={{ marginTop: 16 }}>
            <ListItem key="no_chats">
                <ListItemText
                    primary="У вас пока нет сообщений"
                    primaryTypographyProps={{ align: 'center' }}
                />
            </ListItem>
        </List>

    );
    return (
        <List className={classes.List}>
            {chats.map(chat => (
                <ListItem
                    button
                    key={chat.id.toString()}
                    onClick={() => chat.id !== current ? selectChat(chat.id, socket) : null}
                    selected={chat.id === current}
                >
                    <ListItemAvatar>
                        <Badge
                            color="primary"
                            variant="dot"
                            overlap="circle"
                            className={classes.Badge}
                            invisible={!chat.peer_user.is_online}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Avatar src={chat.peer_user.avatar}/>
                        </Badge>
                    </ListItemAvatar>
                    <ListItemText
                        primary={chat.peer_user.name}
                        secondary={chat.last_message ? chat.last_message.text : null}
                        secondaryTypographyProps={{ noWrap: true }}
                    />
                    <ListItemRight
                        date={chat.last_message ? moment(chat.last_message.created_at, 'DD.MM.YYYY H:m:s.SSSSSS').format('DD.MM HH:mm') : null}
                        unread={chat.unread}
                    />
                </ListItem>
            ))}
        </List>
    );
};

const ListItemRight = ({ date, unread }) => {
    const classes = useStyles();
    if (!date) return null;
    return (
        <div className={classes.ListItemRight}>
            <Typography variant="caption" gutterBottom>{date}</Typography>
            <div>{unread ? <FiberManualRecordIcon color="primary" style={{ fontSize: 15 }} /> : null}</div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    Badge: {
        '& .MuiBadge-dot': {
            backgroundColor: '#4CAF50 !important'
        }
    },
    ListItemRight: {
        display: 'flex',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-end',
        flexShrink: 0,
        marginTop: 9,
        marginLeft: 4,
    },
    List: {
         overflowY: 'auto',
         height: 'calc(100vh - 104px)',
         marginTop: '24px',
         padding: '0px',
         [theme.breakpoints.down('md')]: {
             height: 'calc(100vh - 56px)',
             marginTop: '0px', 
         }
     }
}));

const mapStateToProps = ({ chat }) => ({
    chats: chat.chats,
    current: chat.current
});

export default connect(mapStateToProps, { loadChatList, selectChat })(ChatList)
