import React from 'react';
import "moment/locale/ru";
import { Fab } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from 'formik-material-ui-pickers';

const SecondStageSchema = Yup.object().shape({
  name: Yup.string()
    .required('Это поле обязательно!'),
  birthday: Yup.string()
    .required('Это поле обязательно!'),
});

export default ({ handleNext }) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="ru" >
            <Formik
                initialValues={{ name: '', birthday: null }}
                validationSchema={SecondStageSchema}
                onSubmit={(values) => handleNext({
                    name: values.name,
                    birthday: values.birthday.format('YYYY-MM-DD')
                })}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            name="name"
                            label="Имя"
                        />
                        <Field
                            className="SignupForm-input"
                            component={DatePicker}
                            openTo="year"
                            format="YYYY/MM/DD"
                            views={["year", "month", "date"]}
                            initialFocusedDate={(new Date()).setFullYear( (new Date()).getFullYear() - 18 )}
                            maxDate={(new Date()).setFullYear( (new Date()).getFullYear() - 18 )}
                            invalidDateMessage="Не правильный формат даты"
                            name="birthday"
                            label="День рождения"
                        />
                        <Fab
                            className="SignupForm-submit"
                            variant="extended"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Продолжить
                        </Fab>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};
