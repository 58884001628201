import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { setError } from '../actions';

const AlertDialog = ({ error, setError }) =>  {
    const handleClose = () => {
        setError('alertError', null);
    }

    if (!error) return null;
    return (
        <div>
            <Dialog
                open={!!error}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = ({ errors }) => ({
    error: errors.alertError
});


export default connect(mapStateToProps, { setError })(AlertDialog);
