import {
    CLEAR_PROFILE,
    LOAD_PROFILE,
    LOAD_QUESTIONS,
    EDIT_PROFILE,
    UPLOAD_PROFILE_IMAGE,
    REMOVE_PROFILE_IMAGE,
    TOGGLE_SUBSCRIPTION_DIALOG,
    TOGGLE_PAYMENT_DIALOG,
    TOGGLE_PAYMENT_FORM_LOADING,
    CANCEL_SUBSCRIPTION
} from '../actions/types';

const INITIAL_STATE = {
    traits: null,
    questions: null,
    images: null,
    showSubscriptionDialog: false,
    showPaymentDialog: false,
    showPaymentFormLoading: false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case CLEAR_PROFILE: {
            return {
                traits: null,
                questions: null,
                images: null,
                showSubscriptionDialog: false
            };
        }

        case LOAD_PROFILE: {
            return {
                ...state,
                ...action.payload
            };
        }

        case LOAD_QUESTIONS: {
            return {
                ...state,
                questions: { ...action.payload }
            };
        }

        case EDIT_PROFILE: {
            return {
                ...state,
                traits: { ...action.payload }
            };
        }

        case UPLOAD_PROFILE_IMAGE: {
            return {
                ...state,
                images: [...state.images, action.payload]
            };
        }

        case REMOVE_PROFILE_IMAGE: {
            let images = state.images.filter(image => image.id !== action.payload);
            return {
                ...state,
                images
            };
        }

        case TOGGLE_SUBSCRIPTION_DIALOG: {
            return {
                ...state,
                showSubscriptionDialog: action.payload || false
            };
        }

        case TOGGLE_PAYMENT_DIALOG: {
            return {
                ...state,
                showPaymentDialog: action.payload || false
            };
        }

        case TOGGLE_PAYMENT_FORM_LOADING: {
            return {
                ...state,
                showPaymentFormLoading: action.payload || false
            };
        }

        case CANCEL_SUBSCRIPTION: {
            return {
                ...state,
                traits: {
                    ...state.traits,
                    is_cancel_subscription: true
                }
            };
        }

        default:
            return state;
    }
};

