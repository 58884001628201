import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { loadChatList } from '../../actions/chat';
import ChatList from './ChatList';
import Chat from './Chat';
 

const Messenger = ({ socket, chats, current, loadChatList }) => {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (!chats) loadChatList();
    }, [chats, loadChatList]);

    if (!chats) return null;
    
    if (!upMd && !current) {
        return <ChatList socket={socket} />;
    } else if (!upMd && current) {
        return <Chat socket={socket} />;
    }

    return (
        <Grid container>
            <Grid item xs={12} md={4} xl={4}>
                <ChatList socket={socket}/>
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
                <Chat socket={socket}/>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = ({ chat }) => ({
    chats: chat.chats,
    current: chat.current,
});

export default connect(mapStateToProps, { loadChatList })(Messenger);
