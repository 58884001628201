import React from 'react';
import { connect } from 'react-redux';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    Typography,
    Divider,
    Badge,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, NavLink } from 'react-router-dom';
import { Tune as TuneIcon } from '@material-ui/icons';

import './Sidebar.sass';
import { toggleFilter } from '../actions/search';
import { toggleSubscriptionDialog } from '../actions/profile';

const Sidebar = ({ avatar, name, isPremium, isCancelSubscription, hasNewMessages, toggleFilter, toggleSubscriptionDialog }) => {
    const classes = useStyles();

    return (
        <div className="Sidebar">
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar src={avatar} variant="square" className={classes.Avatar} />
                </Grid>
                <Grid item>
                    <Typography variant="h6" style={{ lineHeight: '1.3' }}>{name}</Typography>
                    <Link to="/profile/edit" className="Link">Редактировать профиль</Link>
                </Grid>
            </Grid>
            <List component="nav" className={classes.Nav}>
                <ListItem
                    button
                    to="/search"
                    component={NavLink}
                    activeClassName={classes.NavLinkActive}
                    className={classes.NavLink}
                    disableRipple
                >
                    <ListItemText primary="Знакомства" />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => toggleFilter(true)}>
                            <TuneIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem
                    button
                    to="/chat"
                    component={NavLink}
                    activeClassName={classes.NavLinkActive}
                    className={classes.NavLink}
                    disableRipple
                >
                    <ListItemText primary="Сообщения" />
                    <Badge
                        color="primary"
                        variant="dot"
                        style={{ marginLeft: 'auto', marginRight: 8 }}
                        invisible={!hasNewMessages}
                    />
                </ListItem>
                <Divider />
                <ListItem
                    button
                    to="/profile"
                    component={NavLink}
                    activeClassName={classes.NavLinkActive}
                    className={classes.NavLink}
                    disableRipple
                >
                    <ListItemText primary="Личный кабинет" />
                </ListItem>
            </List>
            {!isPremium ? (
              <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => toggleSubscriptionDialog(true)}
              >
                  Повысить статус
              </Button>
            ) : null}
            <div className="text-center" style={{ marginTop: 24 }}>
                <Typography
                    variant="caption"
                    component="div"
                    className={classes.InfoText}
                >
                    {new Date().getFullYear()} © LOVA
                </Typography>
                <Typography
                    variant="caption"
                    component={Link}
                    display="block"
                    to="/about"
                    className={classes.InfoText}
                >
                    О сервисе
                </Typography>
                <Typography
                    variant="caption"
                    component="a"
                    display="block"
                    target="_blank"
                    href="/static/privatepolicy.pdf"
                    className={classes.InfoText}
                >
                    Политика конфиденциальности
                </Typography>
                <Typography
                    variant="caption"
                    component="a"
                    display="block"
                    target="_blank"
                    href="/static/contractoffer.pdf"
                    className={classes.InfoText}
                >
                    Договор оферты
                </Typography>
                <Typography
                    variant="caption"
                    component="a"
                    display="block"
                    target="_blank"
                    href="mailto:manager@lovapp.ru"
                    className={classes.InfoText}
                >
                    Задать вопрос
                </Typography>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    Avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        borderRadius: theme.spacing(1)
    },
    Nav: {
        marginTop: 36,
        marginBottom: 36
    },
    NavLink: {
        paddingLeft: 0,
        '&:hover': {
            background: 'transparent'
        }
    },
    NavLinkActive: {
        color: theme.palette.primary.main,
        '& span': {
            fontWeight: 600
        }
    },
    InfoText: {
        color: '#b1b1b1'
    }
}));

const mapStateToProps = ({ profile, chat }) => ({
    avatar: profile.traits.avatar,
    isPremium: profile.traits.is_premium,
    isCancelSubscription: profile.traits.is_cancel_subscription,
    name: profile.traits.name,
    hasNewMessages: chat.chats.some(el => el.unread)
});

export default connect(mapStateToProps, { toggleFilter, toggleSubscriptionDialog })(Sidebar);
