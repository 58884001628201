import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, Typography, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import './Search.sass';
import { loadUsers, loadUsersAnonymously } from '../../actions/search';
import generateLocation from '../../helpers/generateLocation';
import Trait from '../profile/Trait';
import ImageSlider from './ImageSlider';
import SearchActions from './SearchActions';

const Search = ({ isSignedIn, users, currentUser, loading, questions, loadUsers, loadUsersAnonymously }) => {
    const classes = useStyles();

    useEffect(() => {
        if (!users)  {
            if (isSignedIn) {
                loadUsers();
            } else {
                loadUsersAnonymously();
            }
        }
    }, [users, isSignedIn, loadUsers, loadUsersAnonymously]);

    if (loading) return (
        <Paper variant="outlined" className={classes.PaperLoading}>
            <CircularProgress />
        </Paper>
    );

    if (!currentUser && users && users.length === 0) return (
        <Paper variant="outlined" className={classes.PaperLoading}>
            <Typography variant="h6">Ничего не найдено</Typography>
            <Typography variant="subtitle1">Попробуйте изменить параметры поиска</Typography>
        </Paper>
    );

    return (
        <Paper variant="outlined" className={classes.Paper} >
            <Grid container alignItems="stretch">
                <Grid item xs={12} md={8} xl={8} style={{ position: 'relative' }}>
                    <ImageSlider images={currentUser.images} userId={currentUser.id} />
                    <SearchActions user={currentUser} />
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                    <div className={classes.TraitBox}>
                        <Typography variant="h6" color="secondary">
                            {currentUser.name}, {currentUser.age}
                            <Typography variant="caption" className={classes.IsOnline}>
                                {currentUser.is_online ? 'online' : 'offline'}
                            </Typography>
                        </Typography>
                    </div>
                    <Divider />
                    <div className={classes.TraitBox}>
                        <Typography variant="h6" gutterBottom>Обо мне</Typography>
                        <Trait label="Отношения" trait="relationship" value={currentUser.relationship} />
                        <Trait label="Внешность" value={`${currentUser.height} см, ${currentUser.weight} кг`} />
                        <Trait label="Хочу найти" trait="search_gender" value={currentUser.search_gender} />
                        <Trait label="Цель поиска" trait="search_purpose" value={currentUser.search_purpose} />
                        <Trait label="Дети" trait="children" value={currentUser.children} />
                        <Trait label="Доход" trait="finance" value={currentUser.finance} />
                        <Trait label="Алкоголь" trait="alcohol" value={currentUser.alcohol} />
                        <Trait label="Табак" trait="smoking" value={currentUser.smoking} />
                    </div>
                    <div className={classes.TraitBox}>
                        <Typography variant="h6" gutterBottom>Местоположение</Typography>
                        <Trait label={generateLocation(questions.countries, currentUser.country, currentUser.city)} />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    Paper: {
        marginTop: theme.spacing(4),
        borderRadius: '24px',
        overflow: 'hidden',
        minHeight: '80vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            borderRadius: 0,
            border: 'none'
        }
    },
    PaperLoading: {
        marginTop: theme.spacing(4),
        borderRadius: '24px',
        overflow: 'hidden',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            borderRadius: 0,
            border: 'none'
        }
    },
    TraitBox: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    IsOnline: {
        color: '#b5b5b5',
        paddingLeft: 8,
        fontSize: '14px'
    }
}));

const mapStateToProps = ({ auth, profile, search }) => ({
    isSignedIn: auth.isSignedIn,
    users: search.users,
    currentUser: search.users ? search.users[search.current] : null,
    questions: profile.questions,
    loading: search.loading
});

export default connect(mapStateToProps, { loadUsers, loadUsersAnonymously })(Search);
