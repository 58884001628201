import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Typography, Fab } from '@material-ui/core'
import { Formik, Form as FormikForm, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import './AuthForm.sass';
import logoUrl from '../../assets/logo-red.png';

const RecoverySchema = Yup.object().shape({
  email: Yup.string()
    .email('Не валидный Email!')
    .required('Это поле обязательно!'),
});

const RecoveryForm = () => {
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (formValues) => {
        try {
            await axios.post('/api/users/recovery/', formValues);
            setSuccess(true);
        } catch (error) {
            setErrors(error.response.data);
        }
    }
    return (
        <div className="AuthForm-container">
            <div className="AuthForm">
                <img className="AuthForm-logo" src={logoUrl} alt="lovapp.ru" />
                <FormErrors errors={errors} />
                {success ? <SuccessMessage /> : <Form handleSubmit={handleSubmit}/>}
                <Link to="/" className="AuthForm-recover">На главную</Link>
            </div>
        </div>
    )
}

const Form = ({ handleSubmit }) => (
    <Formik
        initialValues={{ email: '' }}
        validationSchema={RecoverySchema}
        onSubmit={handleSubmit}
    >
        {({ submitForm, isSubmitting }) => (
            <FormikForm>
                <Field
                    className="AuthForm-input"
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                />
                <Fab
                    className="AuthForm-submit"
                    size="large"
                    variant="extended"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Восстановить пароль
                </Fab>
            </FormikForm>
        )}
    </Formik>
);

const SuccessMessage = () => (
    <Typography variant="body1" align="center">
        Письмо со ссылкой на восстановление пароля отправленно вам на почту
    </Typography>
);

const FormErrors = ({ errors }) => {
    if (!errors) return null;
    // Reduce won't run on array with one element
    errors.dummy = [];
    return Object.keys(errors)
        .map(key => errors[key])
        .reduce((prev, curr) => {
            if (typeof prev === 'string') prev = [prev];
            if (typeof curr === 'string') curr = [curr];
            return prev.concat(curr);
        })
        .map((error, index) =>
            <Typography
                color="error"
                variant="subtitle2"
                display="block"
                align="center"
                gutterBottom
                key={index.toString()}
                style={{ maxWidth: 320 }}
            >
                {error}
            </Typography>
        );
}

export default RecoveryForm;
