// Authentication
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_UTM = 'SET_UTM';

// Errors
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Profile
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE';
export const REMOVE_PROFILE_IMAGE = 'REMOVE_PROFILE_IMAGE';
export const TOGGLE_SUBSCRIPTION_DIALOG = 'TOGGLE_SUBSCRIPTION_DIALOG';
export const TOGGLE_PAYMENT_DIALOG = 'TOGGLE_PAYMENT_DIALOG';
export const TOGGLE_PAYMENT_FORM_LOADING = 'TOGGLE_PAYMENT_FORM_LOADING';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CLEAR_PROFILE = "CLEAR_PROFILE";

// Search
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const LOAD_USERS = 'LOAD_USERS';
export const SKIP_USER = 'SKIP_USER';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const CLEAR_SEARCH = "CLEAR_SEARCH";

// Chat
export const LOAD_CHAT_LIST = 'LOAD_CHAT_LIST';
export const SELECT_CHAT = 'SELECT_CHAT';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SET_FORM_HEIGHT = 'SET_FORM_HEIGHT';
export const NEW_CHAT = 'NEW_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const NEED_PREMIUM = 'NEED_PREMIUM';
export const CLEAR_CHAT = "CLEAR_CHAT";
export const PEER_MARK_READ = "PEER_MARK_READ";
export const TOGGLE_BANNED_DIALOG = 'TOGGLE_BANNED_DIALOG';
