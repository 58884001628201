import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress } from '@material-ui/core';

import Message from './Message';
import { loadMessages } from '../../actions/chat';

class MessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagesLoaded: 0
        }
        this.messagesContainer = React.createRef();
    }

    componentDidMount() {
        const messagesContainer = this.messagesContainer.current;
        messagesContainer.scrollTop = messagesContainer.scrollHeight - messagesContainer.clientHeight;
        setTimeout(() => {
            messagesContainer.scrollTop = messagesContainer.scrollHeight - messagesContainer.clientHeight;
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const messagesContainer = this.messagesContainer.current;
        const { messages } = this.props;
        const prevMessages = prevProps.messages;
        if (
            prevMessages.length + 1 === messages.length &&
            prevMessages[0] === messages[0] &&
            messagesContainer.scrollHeight - messagesContainer.scrollTop < messagesContainer.clientHeight * 2
        ) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight - messagesContainer.clientHeight;
        } else if (snapshot !== null) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight - snapshot;
        }
    }

    getSnapshotBeforeUpdate() {
        const messagesContainer = this.messagesContainer.current;
        return messagesContainer.scrollHeight - messagesContainer.scrollTop;
    }

    handleImageLoad = () => {
        this.setState({ imagesLoaded: this.state.imagesLoaded + 1 });
    }

    render() {
        const { messages, classes, loadMessages, peerUserId, peerLastSeenAt, next } = this.props;
        return (
            <div
                key={peerUserId}
                className={classes.MessagesList}
                ref={this.messagesContainer}
            >
                <InfiniteScroll
                    loadMore={() => loadMessages(peerUserId)}
                    hasMore={!!next}
                    isReverse
                    threshold={600}
                    useWindow={false}
                    initialLoad={false}
                    loader={<LinearProgress key="messages-loader" />}
                    style={{ paddingBottom: 88 }}
                >
                    {messages.map(message => (
                        <Message
                            key={message.id}
                            text={message.text}
                            from={message.from_user_id}
                            to={message.to_user_id}
                            image={message.image}
                            createdAt={message.created_at}
                            peerLastSeenAt={peerLastSeenAt}
                            handleImageLoad={this.handleImageLoad}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        );
    }
}

const styles = theme => ({
    MessagesList: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100vh - 172px)',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 56px)',
        }
    }
});

export default connect(null, { loadMessages })(withStyles(styles)(MessageList));
