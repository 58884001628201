import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    FormControlLabel,
    Radio,
    MenuItem,
    Slider,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { RadioGroup, Select, CheckboxWithLabel } from 'formik-material-ui';

import { toggleFilter, setSearchParams } from '../../actions/search';

const SearchFilter = ({ open, params, searchPurposes, searchGender, country, countries, toggleFilter, setSearchParams }) => {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    const history = useHistory();
    const [age, setAge] = useState([
        params ? params.age_min : 18,
        params ? params.age_max : 99,
    ]);
    const classes = useStyles();
    const handleSubmit = (values, { setSubmitting }) => {
        values.age_min = age[0];
        values.age_max = age[1];
        setSearchParams(values, setSubmitting);
        history.push('/search');
        toggleFilter(false);
    };
    return (
        <Formik
            initialValues={params ? params : { gender: searchGender, search_purpose: 'any', is_online: false, is_fresh: false }}
            onSubmit={handleSubmit}
        >
            {({ values, handleSubmit }) => (
                <Dialog
                    onClose={() => toggleFilter(false)}
                    open={open}
                    fullScreen={!upMd}
                >
                    <DialogTitle disableTypography  className={classes.DialogTitle}>
                        <Typography variant="h6" align="center">Настройки поиска</Typography>
                        <IconButton onClick={() => toggleFilter(false)} className={classes.CloseButton}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Form>
                            <Field component={RadioGroup} name="gender" style={{ marginBottom: 24 }}>
                                <Typography gutterBottom variant="subtitle2" className={classes.GenderTitle}>Кого хотите найти</Typography>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4} className={classes.GenderGrid}>
                                        <FormControlLabel
                                            value="M"
                                            control={<Radio color="primary" />}
                                            label="Мужчину"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.GenderGrid}>
                                        <FormControlLabel
                                            value="F"
                                            control={<Radio color="primary" />}
                                            label="Женщину"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.GenderGrid}>
                                        <FormControlLabel
                                            value="ALL"
                                            control={<Radio color="primary" />}
                                            label="Всех"
                                        />
                                    </Grid>
                                </Grid>
                            </Field>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="subtitle2" style={{ marginTop: 16 }}>Какова цель поиска</Typography>
                                    <Field
                                        component={Select}
                                        name="search_purpose"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem value="any">Любая</MenuItem>
                                        {
                                            Object.keys(searchPurposes)
                                                .map(key => <MenuItem value={key} key={key}>{searchPurposes[key]}</MenuItem>)
                                        }
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="subtitle2">Возраст</Typography>
                                    <Slider
                                        value={age}
                                        name="age"
                                        onChange={(e, newValue) => setAge(newValue)}
                                        marks={[
                                            { value: age[0], label: age[0] },
                                            { value: age[1], label: age[1] }
                                        ]}
                                        min={18}
                                        max={99}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="is_online"
                                        color="primary"
                                        checked={values.is_online}
                                        component={CheckboxWithLabel}
                                        Label={{ label: 'Показывать только онлайн' }}
                                    />
                                    <Field
                                        name="is_fresh"
                                        color="primary"
                                        component={CheckboxWithLabel}
                                        checked={values.is_fresh}
                                        Label={{ label: 'Показывать только новых людей' }}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            style={{ textTransform: 'none' }}
                            onClick={handleSubmit}
                        >
                            Применить
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

const useStyles = makeStyles((theme) => ({
    DialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    GenderGrid: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
    },
    GenderTitle: {
        [theme.breakpoints.down('sm')]: {
            dispaly: 'block',
            marginBottom: 16
        },
    }
}));

const mapStateToProps = ({ search, profile }) => ({
    open: search.filterOpen,
    params: search.params,
    searchPurposes: profile.questions.search_purpose,
    searchGender: profile.traits.search_gender,
    country: profile.traits.country,
    countries: profile.questions.countries
});

export default connect(mapStateToProps, { toggleFilter, setSearchParams })(SearchFilter);
