import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Fab, Button } from '@material-ui/core'
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import './AuthForm.sass';
import { signIn } from '../../actions';
import logoUrl from '../../assets/logo-red.png';
import SiteInfo from '../SiteInfo';
import AnimatedPhotos from './AnimatedPhotos';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const AuthSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, 'Не валидный телефон!')
    .required('Это поле обязательно!'),
  password: Yup.string()
    .min(8, 'Минимальная длина пароля 8 символов!')
    .max(32, 'Максимальная длина пароля 32 символа!')
    .required('Это поле обязательно!'),
});

const AuthForm = ({ signIn, errors }) => {
    return (
        <div className="AuthForm-container">
            <div className="AuthForm">
                <img className="AuthForm-logo" src={logoUrl} alt="" />
                <Typography
                    color="primary"
                    variant="h5"
                    align="center"
                    gutterBottom
                >
                    5 793 242
                </Typography>
                <Typography
                    variant="body2"
                    align="center"
                >
                    желающих познакомиться
                </Typography>
                <FormErrors errors={errors} />
                <Formik
                    initialValues={{ phone: '', password: '' }}
                    validationSchema={AuthSchema}
                    onSubmit={signIn}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <Field
                                className="AuthForm-input"
                                component={TextField}
                                name="phone"
                                type="phone"
                                label="Телефон"
                                size="medium"
                            />
                            <Field
                                className="AuthForm-input"
                                component={TextField}
                                name="password"
                                type="password"
                                label="Пароль"
                            />
                            <Fab
                                className="AuthForm-submit"
                                size="large"
                                variant="extended"
                                color="secondary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                            >
                                Войти
                            </Fab>
                        </Form>
                    )}
                </Formik>
                <Link to="/auth/recover" className="AuthForm-recover">Восстановить пароль</Link>
                <div className="AuthForm-or">
                    или
                </div>
                <Button
                    color="primary"
                    size="large"
                    fullWidth
                    component={Link}
                    to="/auth/signup"
                >
                    Зарегистрируйтесь
                </Button>
                <SiteInfo size="double-line" />
            </div>
            <AnimatedPhotos />
        </div>
    )
}


const FormErrors = ({ errors }) => {
    if (!errors) return null;
    // Reduce won't run on array with one element
    errors.dummy = [];
    return Object.keys(errors)
        .map(key => errors[key])
        .reduce((prev, curr) => {
            if (typeof prev === 'string') prev = [prev];
            if (typeof curr === 'string') curr = [curr];
            return prev.concat(curr);
        })
        .map((error, index) =>
            <Typography
                color="error"
                variant="subtitle2"
                display="block"
                align="center"
                gutterBottom
                key={index.toString()}
                style={{ maxWidth: 320 }}
            >
                {error}
            </Typography>
        );
}

const mapStateToProps = ({ errors }) => ({
    errors: errors.signIn
});

export default connect(mapStateToProps, { signIn })(AuthForm);
