import React from 'react';
import { Link } from 'react-router-dom';
import { Button, AppBar, Toolbar, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import logoUrl from '../assets/logo-white.png';

import { signOut } from '../actions';
import MobileMenu from './MobileMenu';

const Header = ({ isSignedIn, signOut }) => {
    const classes = useStyles();
    return (
        <AppBar
            classes={{ root: classes.AppBar }}
            elevation={0}
            position="static"
        >
            <Container disableGutters>
                <Toolbar>
                    {isSignedIn ? <MobileMenu /> : <div />}
                    <img src={logoUrl} alt="lovapp.ru" className={isSignedIn ? classes.Logo : classes.LogoLeft} />
                    {isSignedIn ? <LogoutButton signOut={signOut} /> : <AuthButtons /> }
                </Toolbar>
            </Container>
        </AppBar>
    );
}

const AuthButtons = () => {
    const classes = useStyles();
    return (<>
        <Button
            color="inherit"
            component={Link}
            to="/auth/signin"
            classes={{ root: classes.Button }}
        >
            Вход
        </Button>
        <Button
            variant="contained"
            color="default"
            component={Link}
            to="/auth/signup"
            classes={{ root: classes.ButtonContained }}
        >
            Регистрация
        </Button>
    </>);
};

const LogoutButton = ({ signOut }) => {
    const classes = useStyles();
    return (
        <Button
            color="inherit"
            onClick={signOut}
            classes={{ root: classes.Button }}
        >
            Выход
        </Button>
    );
};

const useStyles = makeStyles((theme) => ({
    AppBar: {
        background: 'linear-gradient(69deg, #be0200, #bd4200,#bd4200)',
        color: '#ffffff'
    },
    Button: {
        textTransform: 'none'
    },
    ButtonContained: {
        textTransform: 'none',
        background: '#ffffff'
    },
    Logo: {
        marginRight: 'auto',
        height: '28px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto'
        }
    },
    LogoLeft: {
        marginRight: 'auto',
        height: '28px',
        width: 'auto',
    }
}));

const mapStateToProps = ({ auth }) => ({
    isSignedIn: auth.isSignedIn
});

export default connect(mapStateToProps, { signOut })(Header);
