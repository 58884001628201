import {
    CLEAR_SEARCH,
    SET_SEARCH_PARAMS,
    TOGGLE_FILTER,
    LOAD_USERS,
    SKIP_USER,
    SET_SEARCH_LOADING,
} from '../actions/types';
import { loadFromStorage } from '../helpers/localStorage';

const searchParams = loadFromStorage('searchParams');
const INITIAL_STATE = {
    filterOpen: false,
    loading: true,
    params: searchParams ? searchParams : null,
    users: null,
    current: null,
    nextPage: null,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case CLEAR_SEARCH: {
            return {
                filterOpen: false,
                loading: true,
                params: null,
                users: null,
                current: null,
                nextPage: null,
            };
        }

        case LOAD_USERS: {
            return {
                ...state,
                users: action.payload.results,
                nextPage: action.payload.next,
                current: 0,
                loading: false
            };
        }

        case TOGGLE_FILTER: {
            return {
                ...state,
                filterOpen: action.payload
            };
        }

        case SET_SEARCH_PARAMS: {
            return {
                ...state,
                params: action.payload
            };
        }

        case SKIP_USER: {
            return {
                ...state,
                current: state.current + 1
            };
        }

        case SET_SEARCH_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }

        default:
            return state;
    }
};

