import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../../api';
import {
    Paper,
    Grid,
    Divider,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
    CircularProgress,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
    ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';


import generateLocation from '../../helpers/generateLocation';
import Trait from '../profile/Trait';
import ImageSlider from '../search/ImageSlider';
import Report from '../search/Report';


const ChatProfile = ({ open, userId, onClose, questions }) => {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    const [currentUser, setCurrentUser] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        (async () => {
            const response = await api.get(`/api/users/anketa_info/${userId}/`);
            setCurrentUser(response.data);
        })();
    }, [setCurrentUser, userId]);

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullScreen={!upMd}
        >
            <DialogContent style={upMd ? { width: '600px', minHeight: '500px', padding: 0 } : { padding: 0 }}>
                <IconButton
                    onClick={onClose}
                    className={classes.CloseButton}
                >
                    {upMd ? <CloseIcon /> : <ArrowBackIcon />}
                </IconButton>
                {currentUser ?
                    <Grid container alignItems="stretch">
                        <Grid item xs={12} style={{ position: 'relative' }}>
                            <ImageSlider images={currentUser.images} userId={currentUser.id} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.TraitBox}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item xs="auto">
                                        <Typography variant="h6" color="secondary">
                                            {currentUser.name}, {currentUser.age}
                                            <Typography variant="caption" className={classes.IsOnline}>
                                                {currentUser.is_online ? 'online' : 'offline'}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Report toUserId={currentUser.id}/>
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider />
                            <div className={classes.TraitBox}>
                                <Typography variant="h6" gutterBottom>Обо мне</Typography>
                                <Trait label="Отношения" trait="relationship" value={currentUser.relationship} />
                                <Trait label="Внешность" value={`${currentUser.height} см, ${currentUser.weight} кг`} />
                                <Trait label="Хочу найти" trait="search_gender" value={currentUser.search_gender} />
                                <Trait label="Цель поиска" trait="search_purpose" value={currentUser.search_purpose} />
                                <Trait label="Дети" trait="children" value={currentUser.children} />
                                <Trait label="Доход" trait="finance" value={currentUser.finance} />
                                <Trait label="Алкоголь" trait="alcohol" value={currentUser.alcohol} />
                                <Trait label="Табак" trait="smoking" value={currentUser.smoking} />
                            </div>
                            <div className={classes.TraitBox}>
                                <Typography variant="h6" gutterBottom>Местоположение</Typography>
                                <Trait label={generateLocation(questions.countries, currentUser.country, currentUser.city)} />
                            </div>
                        </Grid>
                    </Grid>
                :
                    <Paper variant="outlined" className={classes.PaperLoading}>
                        <CircularProgress />
                    </Paper>
                }
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        background: '#fff !important',
        zIndex: 999999,
        [theme.breakpoints.down('md')]: {
            right: 'unset',
            left: theme.spacing(1),
            top: theme.spacing(1),
        }
    },
    GenderGrid: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
    },
    GenderTitle: {
        [theme.breakpoints.down('sm')]: {
            dispaly: 'block',
            marginBottom: 16
        },
    },
    PaperLoading: {
        marginTop: 24,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    TraitBox: {
        padding: '16px 24px',
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
        }
    },
}));

const mapStateToProps = ({ profile }) => ({
    questions: profile.questions,
});

export default connect(mapStateToProps)(ChatProfile);
