import {
    SIGN_IN,
    SIGN_OUT,
    REFRESH_TOKEN,
    SET_UTM,
} from '../actions/types';
import { loadFromStorage } from '../helpers/localStorage';

const localState = loadFromStorage('auth');
const localUtm = loadFromStorage('utm');
const isSignedIn = localState && localState.refreshExpires > new Date().getTime() ? true : false
const INITIAL_STATE = {
    isSignedIn: isSignedIn,
    token: localState && isSignedIn ? localState.token : null,
    refresh: localState && isSignedIn ? localState.refresh : null,
    expires: localState && isSignedIn ? localState.expires : null,
    refreshExpires: localState && isSignedIn ? localState.refreshExpires : null,
    utm: localUtm ? localUtm : null,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SIGN_IN: {
            return {
                ...state,
                ...action.payload
            };
        }

        case SIGN_OUT: {
            return {
                isSignedIn: false,
                token: null,
                refresh: null,
                expires: null,
                refreshExpires: null
            };
        }
        case REFRESH_TOKEN: {
            return {
                ...state,
                ...action.payload
            };
        }
        case SET_UTM: {
            return {
                ...state,
                utm: action.payload
            };
        }
        default:
            return state;
    }
};

