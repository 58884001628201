import React from 'react';
import * as Yup from 'yup';
import Fab from '@material-ui/core/Fab';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { InputAdornment } from '@material-ui/core';

const ForthStageSchema = Yup.object().shape({
  height: Yup.number()
    .min(100)
    .required('Это поле обязательно!'),
  weight: Yup.number()
    .min(20)
    .required('Это поле обязательно!'),
});

export default ({ handleNext }) => {
    return (
        <div>
            <Formik
                initialValues={{ height: '', weight: '' }}
                onSubmit={(values) => handleNext(values)}
                validationSchema={ForthStageSchema}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            type="number"
                            name="height"
                            label="Рост"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">см</InputAdornment>,
                            }}
                        />
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            type="number"
                            name="weight"
                            label="Вес"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">кг</InputAdornment>,
                            }}
                        />
                        <Fab
                            className="SignupForm-submit"
                            variant="extended"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Продолжить
                        </Fab>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
