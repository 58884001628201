import React, { useState } from 'react';
import { Menu as MenuIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
    SwipeableDrawer,
    Button,
    IconButton,
    Divider,
    Hidden,
    Badge,
    Avatar,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavLink, Link } from 'react-router-dom';
import { Tune as TuneIcon } from '@material-ui/icons';

import { toggleFilter } from '../actions/search';
import { toggleSubscriptionDialog } from '../actions/profile';

const MobileMenu = ({ avatar, name, email, isPremium, hasNewMessages, toggleFilter, toggleSubscriptionDialog }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Hidden mdUp>
            <IconButton onClick={() => setOpen(!open)} edge="start">
                <MenuIcon style={{ color: '#fff' }} />
            </IconButton>
            <SwipeableDrawer
                anchor="left"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
            >
                <UserInfo
                    avatar={avatar}
                    name={name}
                    email={email}
                />
                <List
                    component="nav"
                    onClick={() => setOpen(false)}
                    className={classes.Nav}
                >
                    <ListItem
                        button
                        to="/search"
                        component={NavLink}
                        activeClassName={classes.NavLinkActive}
                    >
                        <ListItemText primary="Знакомства" />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                onClick={() => toggleFilter(true)}
                            >
                                <TuneIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        to="/chat"
                        component={NavLink}
                        activeClassName={classes.NavLinkActive}
                    >
                        <ListItemText primary="Сообщения" />
                        <Badge
                            color="primary"
                            variant="dot"
                            style={{ marginLeft: 'auto', marginRight: 8 }}
                            invisible={!hasNewMessages}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        to="/profile"
                        component={NavLink}
                        activeClassName={classes.NavLinkActive}
                    >
                        <ListItemText primary="Личный кабинет" />
                    </ListItem>
                </List>
                {!isPremium ? (
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            toggleSubscriptionDialog(true);
                            setOpen(false);
                        }}
                        className={classes.SubscriptionButton}
                    >
                        Повысить статус
                    </Button>
                ) : null}
                <div className="text-center" style={{ padding: 16 }}>
                    <Typography
                        variant="caption"
                        component="div"
                        className={classes.InfoText}
                    >
                        {new Date().getFullYear()} © LOVA
                    </Typography>
                    <Typography
                        variant="caption"
                        component={Link}
                        display="block"
                        to="/about"
                        className={classes.InfoText}
                    >
                        О сервисе
                    </Typography>
                    <Typography
                        variant="caption"
                        component="a"
                        display="block"
                        target="_blank"
                        href="/static/privatepolicy.pdf"
                        className={classes.InfoText}
                    >
                        Политика конфиденциальности
                    </Typography>
                    <Typography
                        variant="caption"
                        component="a"
                        display="block"
                        target="_blank"
                        href="/static/contractoffer.pdf"
                        className={classes.InfoText}
                    >
                        Договор оферты
                    </Typography>
                    <Typography
                        variant="caption"
                        component="a"
                        display="block"
                        target="_blank"
                        href="mailto:manager@lovapp.ru"
                        className={classes.InfoText}
                    >
                        Задать вопрос
                    </Typography>
                </div>
            </SwipeableDrawer>
        </Hidden>
    );
}

const UserInfo = ({ avatar, name, email }) => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: 16,
        width: 250
    }}>
        <Avatar src={avatar} style={{ marginRight: 12, width: 42, height: 42 }} />
        <div style={{ paddingTop: 4 }}>
            <Typography
                variant="subtitle1"
                style={{
                    lineHeight: 1,
                    display: 'block',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 164
                }}
            >
                {name}
            </Typography>
            <Typography variant="caption">{email}</Typography>
        </div>
    </div>
);

const useStyles = makeStyles((theme) => ({
    Avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        borderRadius: theme.spacing(1)
    },
    Nav: {
        width: 250,
        flexGrow: 1
    },
    NavLinkActive: {
        color: theme.palette.primary.main,
        '& span': {
            fontWeight: 600
        }
    },
    InfoText: {
        color: '#b1b1b1'
    },
    SubscriptionButton: {
        margin: 16,
        width: 'calc(100% - 32px)'
    },
}));

const mapStateToProps = ({ profile, chat }) => ({
    isPremium: profile.traits.is_premium,
    avatar: profile.traits.avatar,
    name: profile.traits.name,
    email: profile.traits.email,
    hasNewMessages: chat.chats.some(el => el.unread)
});

export default connect(mapStateToProps, { toggleFilter, toggleSubscriptionDialog })(MobileMenu);
