import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Grid, Typography, Divider, Button, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { editProfile } from '../../actions/profile';
import './Profile.sass';
import Trait from './EditableTrait';
import ProfileImages from './ProfileImages';


const editProfileSchema = Yup.object().shape({
  name: Yup.string().required('Это поле обязательно!'),
  birthday: Yup.string().required('Это поле обязательно!'),
  gender: Yup.string().required('Это поле обязательно!'),
  appearance: Yup.string().required('Это поле обязательно!'),
  weight: Yup.number('Введите число').required('Это поле обязательно!'),
  height: Yup.number('Введите число').required('Это поле обязательно!'),
  relationship: Yup.string().required('Это поле обязательно!'),
  education: Yup.string().required('Это поле обязательно!'),
  children: Yup.string().required('Это поле обязательно!'),
  finance: Yup.string().required('Это поле обязательно!'),
  alcohol: Yup.string().required('Это поле обязательно!'),
  smoking: Yup.string().required('Это поле обязательно!'),
  search_gender: Yup.string().required('Это поле обязательно!'),
  country: Yup.string().required('Это поле обязательно!'),
  city: Yup.string().required('Это поле обязательно!')
});

const EditProfile = ({ traits, questions, editProfile }) => {
    const history = useHistory();
    const classes = useStyles();

    const handleSubmit = (values, { setSubmitting }) => {
        values.birthday = typeof values.birthday === 'string' ? values.birthday : values.birthday.format('YYYY-MM-DD');
        editProfile(values, setSubmitting, history);
    }

    return (
        <div className="EditProfile">
            <Formik
                initialValues={traits}
                validationSchema={editProfileSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Grid container justify="space-between" style={{ marginTop: 24 }} className={classes.TraitBox}>
                                    <Grid item>
                                        <Typography variant="h6" gutterBottom style={{ fontSize: 26 }}>{traits.name}, {traits.age}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disableElevation
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={classes.Submit}
                                        >
                                            Принять
                                        </Button>
                                        <Button
                                            to="/profile"
                                            component={Link}
                                            variant="outlined"
                                            color="default"
                                            disableElevation
                                            disabled={isSubmitting}
                                            className={classes.Cancel}
                                        >
                                            Отмена
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {isSubmitting ? <LinearProgress /> : <Divider />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.TraitBox}>
                                    <Grid item xs={12}>
                                        <ProfileImages remover={true} uploader={true} showTitle />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                <Grid container className={classes.TraitBox}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Личные данные</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container className={classes.TraitBox}>
                                    <Grid item xs={12}>
                                        <Trait label="Имя" type="TextField" name="name" />
                                        <Trait label="Возраст" type="DatePicker" name="birthday" />
                                        <Trait variants={questions.gender} label="Пол" name="gender" />
                                        <Trait variants={questions.appearance} label="Внешность" name="appearance" />
                                        <Trait label="Вес" type="TextField" name="weight" />
                                        <Trait label="Рост" type="TextField" name="height"/>
                                        <Trait variants={questions.relationship} label="Отношения" name="relationship"/>
                                        <Trait variants={questions.education} label="Образование" name="education" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container className={classes.TraitBox}>
                                    <Grid item xs={12}>
                                        <Trait variants={questions.children} label="Дети" name="children" />
                                        <Trait variants={questions.finance} label="Доход" name="finance" />
                                        <Trait variants={questions.alcohol} label="Алкоголь" name="alcohol" />
                                        <Trait variants={questions.smoking} label="Табак" name="smoking" />
                                        <Trait variants={questions.search_gender} label="Кого хотите найти" name="search_gender" />
                                        <Trait variants={questions.search_purpose} label="Цель поиска" name="search_purpose"/>
                                        <Trait variants={questions.countries} label="Страна" type="Country" name="country"/>
                                        <Trait variants={questions.countries} values={values} label="Город" type="City" name="city"/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    Submit: {
        marginBottom: theme.spacing(2)
    },
    Cancel: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    TraitBox: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
        }
    }
}));

const mapStateToProps = ({ profile }) => ({
    traits: profile.traits,
    questions: profile.questions
});

export default connect(mapStateToProps, { editProfile })(EditProfile);
