import React, { useEffect } from 'react';
import { Grid, LinearProgress, Container, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Header from '../Header';
import Search from '../search/Search';
import { loadQuestions } from '../../actions/profile';
import SiteInfo from '../SiteInfo';

const Home = ({ questionsLoaded, loadQuestions }) => {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    useEffect(() => {loadQuestions()}, [loadQuestions]);

    if (!questionsLoaded) return <LinearProgress />;
    return (
        <div className="Home">
            <Header />
            <Container disableGutters={!upMd}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} xl={9}>
                        <Search />
                        <SiteInfo />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

const mapStateToProps = ({ profile }) => ({
    questionsLoaded: profile.questions ? true : false
});

export default connect(mapStateToProps, { loadQuestions })(Home);
