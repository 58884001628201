import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    InputBase,
    Paper,
    Fab,
} from '@material-ui/core';
import {
    SendOutlined as SendIcon,
} from '@material-ui/icons';

import { sendMessage } from '../../actions/chat';
import SendPhotoButton from './SendPhotoButton';

const MessageForm = ({ socket, fromUserId, toUserId, sendMessage }) => {
    const [message, setMessage] = useState('');
    const classes = useStyles();

    const handleSend = () => {
        if (message) {
            sendMessage(socket, {
                from_user_id: fromUserId,
                to_user_id: toUserId,
                text: message.trim()
            });
            setMessage('');
        }
    };

    const handleSendOnEnter = (event) => {
         if (event.keyCode === 13 && !event.shiftKey) {
             event.preventDefault(); 
             handleSend();
         }
     }

    return (
        <div className={classes.MessageForm}>
            <Paper
                className={classes.TextField}
                elevation={5}
            >
                <InputBase
                    onChange={e => setMessage(e.target.value)}
                    nKeyDown={handleSendOnEnter}
                    value={message}
                    color="primary"
                    placeholder="Написать сообщение"
                    rowsMax={4}
                    multiline
                    fullWidth
                />
                <SendPhotoButton
                    socket={socket}
                    sendMessage={sendMessage}
                    fromUserId={fromUserId}
                    toUserId={toUserId}
                />
            </Paper>
            <Fab className={classes.SendBtn} onClick={handleSend}>
                <SendIcon color={message.trim() ? 'primary' : 'disabled'} />
            </Fab>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    MessageForm: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        padding: '0px 16px 16px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            padding: '0px 16px 8px',
        }
    },
    TextField: {
        display: 'flex',
        alignItems: 'flex-end',
        width: 'calc(100% - 76px)',
        marginRight: theme.spacing(1),
        padding: '12px 24px',
        borderRadius: 26,
        '& input': {
            borderRadius: 50
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 64px)',
        },
    },
    SendBtn: {
        background: '#ffffff'
    }
}));

const mapStateToProps = ({ chat, profile }) => ({
    fromUserId: profile.traits.id,
    toUserId: chat.chats.find(item => item.id === chat.current).peer_user.id,
});

export default connect(mapStateToProps, { sendMessage })(MessageForm);
