import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import './App.sass';
import { setUTM } from './actions';
import theme from './helpers/materialUiTheme';
import Auth from './components/pages/Auth';
import Home from './components/pages/Home';
import About from './components/pages/About';
import PaymentSuccess from './components/pages/PaymentSuccess';
import PaymentProcess from './components/pages/PaymentProcess';
import PaymentSuccessMobile from './components/pages/PaymentSuccessMobile';
import PaymentFail from './components/pages/PaymentFail';
import MobilePayment from './components/pages/MobilePayment';
import Main from './components/pages/Main';
import InitialPhotoUpload from './components/pages/InitialPhotoUpload';

function App({ isSignedIn, setUTM }) {
    useEffect(() => {
        const utm = queryString.parse(window.location.search);
        if (!isEmpty(utm)) {
            setUTM(utm);
        }
    });
    return (
        <>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    {isSignedIn ? <PrivateRoutes /> : <PublicRoutes />}
                </div>
            </MuiThemeProvider>
        </>
    );
}

const PublicRoutes = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/auth" component={Auth} />
        <Route path="/about" component={About} />
        <Route path="/app_payment" component={MobilePayment} />
        <Route path="/payment-result" component={PaymentProcess} />
        <Route path="/payment-success" component={PaymentSuccessMobile} />
        <Redirect from="*" to="/auth" />
    </Switch>
);

const PrivateRoutes = () => (
    <Switch>
        <Route path="/profile" component={Main} />
        <Route path="/search" component={Main} />
        <Route path="/chat" component={Main} />
        <Route path="/about" component={About} />
        <Route path="/payment-result" component={PaymentProcess} />
        <Route path="/payment-success" component={PaymentSuccess} />
        <Route path="/payment-fail" component={PaymentFail} />
        <Route path="/photo-upload" component={InitialPhotoUpload} />
        <Route path="/app_payment" component={MobilePayment} />
        <Redirect from="*" to="/profile" />
    </Switch>
);

const mapStateToProps = ({ auth }) => ({
    isSignedIn: auth.isSignedIn
});

export default connect(mapStateToProps, { setUTM })(App);
