import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: "#BA0B87",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#fa0401",
            contrastText: "#ffffff"
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none',
                borderRadius: '6px',
                // minWidth: 110
            },
            outlined: {
                borderRadius: '6px',
                textTransform: 'none'
            }
        }
    }
});

export default theme;
