import {
    ADD_ERROR,
    REMOVE_ERROR,
    CLEAR_ERRORS
} from '../actions/types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case ADD_ERROR: {
			return {
				...state,
				...action.payload
			};
		}

		case REMOVE_ERROR: {
			return {
				...state,
                [action.payload]: null
			};
		}

		case CLEAR_ERRORS: {
			return {};
		}

		default:
			return state;
	}
};

