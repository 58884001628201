import React, { useState } from 'react';

import girl_1 from '../../assets/girl.png';
import girl_2 from '../../assets/girl1.png';
import girl_3 from '../../assets/girl2.png';
import girl_4 from '../../assets/girl3.png';

import './AnimatedPhotos.sass';

export default () => {
    let [loadedImages, setLoadedImages] = useState(0);
    return <>
        <img src={girl_1} onLoad={() => setLoadedImages(loadedImages + 1)} alt="" className="AnimatedPhotos_1" style={{ display: loadedImages === 4 ? 'block' : 'none' }} />
        <img src={girl_2} onLoad={() => setLoadedImages(loadedImages + 1)} alt="" className="AnimatedPhotos_2" style={{ display: loadedImages === 4 ? 'block' : 'none' }} />
        <img src={girl_3} onLoad={() => setLoadedImages(loadedImages + 1)} alt="" className="AnimatedPhotos_3" style={{ display: loadedImages === 4 ? 'block' : 'none' }} />
        <img src={girl_4} onLoad={() => setLoadedImages(loadedImages + 1)} alt="" className="AnimatedPhotos_4" style={{ display: loadedImages === 4 ? 'block' : 'none' }} />
    </>;
};
