import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    CircularProgress,
    Paper,
    Divider,
    AppBar,
    Toolbar,
    Avatar,
    Typography,
    IconButton,
    Hidden,
    useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    ArrowBack as BackIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { loadMessages, selectChat } from '../../actions/chat';
import MessageForm from './MessageForm';
import MessageList from './MessageList';
import ChatProfile from './ChatProfile';

const Chat = ({ socket, id, chats, loadMessages, selectChat }) => {
    const classes = useStyles();
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    const currentChat = chats[chats.findIndex(chat => chat.id === id)];
    const [showProfile, setShowProfile] = useState(false);

    useEffect(() => {
        if (id && !currentChat.messagesLoaded && !currentChat.temporary) loadMessages(currentChat.peer_user.id);
    });

    if (!id) return null;
    if (!currentChat.messages) {
        return (
            <Paper
                variant={upMd ? "outlined" : "elevation"}
                className={classes.PaperLoading}
                elevation={0}
            >
                <CircularProgress />
            </Paper>
        );
    }


    return (
        <Paper
            key={id}
            variant={upMd ? "outlined" : "elevation"}
            elevation={0}
            className={classes.Paper}
        >
            <AppBar color="inherit" elevation={upMd ? 0 : 1} position={upMd ? "static" : "fixed"}>
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton
                            onClick={() => selectChat(null)}
                            edge="start"
                            style={{ marginRight: 16 }}
                        >
                            <BackIcon />
                        </IconButton>
                    </Hidden>
                    <Avatar
                        src={currentChat.peer_user.avatar}
                        style={{ marginRight: 16, cursor: 'pointer' }}
                        onClick={() => setShowProfile(true)}
                    />
                    <Typography
                        variant="h6"
                        onClick={() => setShowProfile(true)}
                        style={{ cursor: 'pointer' }}
                    >
                        {currentChat.peer_user.name}
                    </Typography>
                    <ChatProfile
                        open={showProfile}
                        userId={currentChat.peer_user.id}
                        onClose={() => setShowProfile(false)}
                        key={currentChat.peer_user.id}
                    />
                </Toolbar>
            </AppBar>
            <Hidden mdDown>
                <Divider />
            </Hidden>
            <MessageList
                messages={currentChat.messages}
                key={currentChat.peer_user.id}
                peerUserId={currentChat.peer_user.id}
                next={currentChat.messagesNext}
                peerLastSeenAt={currentChat.peer_last_seen_at}
            />
            <MessageForm socket={socket} />
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    Paper: {
        marginTop: 24,
        height: 'calc(100vh - 104px)',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            height: 'calc(100vh - 64px)',
        }
    },
    PaperLoading: {
        marginTop: 24,
        height: 'calc(100vh - 104px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 88px)',
        }
    },
    MessagesList: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100vh - 172px)',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 56px)',
        }
    }
}));

const mapStateToProps = ({ chat }) => ({
    id: chat.current,
    chats: chat.chats
});

export default connect(mapStateToProps, { loadMessages, selectChat })(Chat);
