import * as ax from 'axios';
import store from '../store';
import { signOut, refresh } from '../actions';

let localStorage = JSON.parse(window.localStorage.getItem('auth'));
let api = ax.create({
    headers: { 'Authorization': `Bearer ${localStorage ? localStorage.token : null}` }
});

api.interceptors.request.use((config) => {
    let localStorage = JSON.parse(window.localStorage.getItem('auth'));
    let originalRequest = config;
    let token = localStorage.token;
    let tokenIsExpired = localStorage.expires < new Date().getTime();
    let refreshIsExpired = localStorage.refreshExpires < new Date().getTime();

    if(refreshIsExpired){
        store.dispatch(signOut());
    }
    if (token && tokenIsExpired) {
        return issueToken().then((tokens) => {
            originalRequest.headers.Authorization = 'Bearer ' + tokens.access;
            store.dispatch({
                type: "REFRESH_TOKEN",
                payload: {
                    token: tokens.access,
                    expires: new Date().getTime() + 300000
                }
            });
            store.dispatch(refresh({
                token: tokens.access,
                expires: new Date().getTime() + 300000
            }));
            return Promise.resolve(originalRequest);
        })
        .catch(err => {
            store.dispatch(signOut());
        });
    } else {
        originalRequest.headers.Authorization = 'Bearer ' + localStorage.token;
        // return Promise.resolve(originalRequest);
        return config;
    }
}, (err) => {
    return Promise.reject(err);
});

function issueToken() {
    return new Promise((resolve, reject) => {
        let localStorage = JSON.parse(window.localStorage.getItem('auth'));
        return ax.post('/api/token/refresh/', {
            refresh: localStorage.refresh
        }).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            store.dispatch(signOut());
            // reject(err);
        });
    });
}

export default api;
