import React from 'react';
import { Fab } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Select } from 'formik-material-ui';
import * as Yup from 'yup';

const ThirdStageSchema = Yup.object().shape({
  country: Yup.string()
    .required('Это поле обязательно!'),
  city: Yup.string()
    .required('Это поле обязательно!'),
});

export default ({ questions, handleNext }) => {
    const { countries } = questions;
    return (
        <div>
            <Formik
                initialValues={{ country: '', city: '' }}
                onSubmit={(values) => handleNext(values)}
                validationSchema={ThirdStageSchema}
            >
                {({ values, submitForm, isSubmitting }) => (
                    <Form>
                        <FormControl className="SignupForm-input">
                            <InputLabel htmlFor="signup-country">Страна</InputLabel>
                            <Field
                                component={Select}
                                name="country"
                                fullWidth
                                InputProps={{
                                    id: 'signup-country'
                                }}
                            >
                                {countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                            </Field>
                        </FormControl>
                        <FormControl className="SignupForm-input">
                            <InputLabel htmlFor="signup-city">Город</InputLabel>
                            <Field
                                component={Select}
                                name="city"
                                fullWidth
                                disabled={!values.country}
                                InputProps={{
                                    id: 'signup-city'
                                }}
                            >
                                    {
                                        values.country && countries
                                            [countries.findIndex(element => element.id === values.country)]
                                            .cities
                                            .map(city => <MenuItem value={city.id}>{city.name}</MenuItem>)
                                    }
                            </Field>
                        </FormControl>
                        <Fab
                            className="SignupForm-submit"
                            variant="extended"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Продолжить
                        </Fab>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
