import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    Paper,
    Typography
} from '@material-ui/core';
import {
    DoneAll as DoneAllIcon,
    Done as DoneIcon
} from '@material-ui/icons';
import moment from 'moment';

const Message = ({ currentUserId, id, text, from, to, image, createdAt, peerLastSeenAt, handleImageLoad }) => {
    const classes = useStyles();
    return (
        <div
            className={from === currentUserId ? classes.messageContainerRight : classes.messageContainerLeft}
        >
            <Paper
                variant="outlined"
                className={from === currentUserId ? classes.MessageRight : classes.Message}
                style={image ? { background: 'transparent', border: 'none' } : null}
            >
                {image ? <img src={image} alt="" className={classes.Image} onLoad={handleImageLoad} /> : null}
                {text && !image ? <Typography color="inherit" className={classes.MessageTypography}>{text}</Typography> : null}
            </Paper>
            <Typography
                className={classes.Timestamp}
                variant="caption"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {moment(createdAt, 'DD.MM.YYYY H:m:s.SSSSSS').format('DD.MM HH:mm')}
                <ReadIndicator byUser={from === currentUserId} peerLastSeenAt={peerLastSeenAt} createdAt={createdAt} />
            </Typography>
        </div>
    );
};

const ReadIndicator = ({ byUser, peerLastSeenAt, createdAt }) => {
    if (!byUser) return null;
    if (moment(peerLastSeenAt,  'DD.MM.YYYY H:m:s.SSSSSS').valueOf() > moment(createdAt, 'DD.MM.YYYY H:m:s.SSSSSS').valueOf()) {
        return <DoneAllIcon style={{ marginLeft: 2, marginTop: -1, fontSize: 16 }} />;
    } else {
        return <DoneIcon style={{ marginLeft: 2, marginTop: -1, fontSize: 16 }} />;
    }
};

const useStyles = makeStyles(theme => ({
    messageContainerLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: 16,
    },
    messageContainerRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingRight: 16,
    },
    Message: {
        maxWidth: '48%',
        marginTop: 8,
        padding: 12,
        borderRadius: 24,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            maxWidth: '80%',
        }
    },
    MessageRight: {
        maxWidth: '48%',
        marginTop: 8,
        padding: 12,
        borderRadius: 24,
        background: theme.palette.primary.main,
        color: '#ffffff !important',
        [theme.breakpoints.down('md')]: {
            maxWidth: '80%',
        }
    },
    MessageTypography: {
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: 1.36
    },
    Image: {
        width: 'calc(100% + 24px)',
        margin: -12,
        height: 'auto',
        borderRadius: 24,
    },
    Timestamp: {
        margin: '0px 12px',
        color: 'rgba(0, 0, 0, 0.4)'
    }
}));

const mapStateToProps = ({ profile }) => ({
    currentUserId: profile.traits.id
});

export default connect(mapStateToProps)(Message);
