import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
} from '@material-ui/icons';

import { togglePaymentDialog } from '../../actions/profile';
import PaymentWidget from './PaymentWidget.js';

const PaymentDialog = ({ open, togglePaymentDialog, showPaymentFormLoading }) => {
    const classes = useStyles();
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));

    const handleClose = () => {
        if (!showPaymentFormLoading) {
            togglePaymentDialog(false);
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            fullScreen={!upMd}
            open={open}
            disableEnforceFocus
        >
            <DialogTitle
                disableTypography
                className={classes.DialogTitle}
            >
                <Typography variant="h6" align="center" gutterBottom style={{ lineHeight: 1.36 }}>
                    Оплата<br/>
                </Typography>
                <IconButton
                    onClick={handleClose}
                    className={classes.CloseButton}
                >
                    <CloseIcon color="inherit" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={upMd ? classes.DialogContent : classes.DialogContentDownMd} >
                <PaymentWidget />
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    DialogContentDownMd: {
        maxWidth: 'none'
    },
    DialogTitle: {
        margin: 0,
        textAlign: 'center',
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    SubmitButtom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 300,
        marginBottom: 16,
        marginTop: 16,
        marginRight: 'auto',
        marginLeft: 'auto'
    }
}));

const mapStateToProps = ({ profile }) => ({
    open: profile.showPaymentDialog,
    showPaymentFormLoading: profile.showPaymentFormLoading,
});

export default connect(mapStateToProps, { togglePaymentDialog })(PaymentDialog);
