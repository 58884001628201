import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const traitValues = {
    search_purpose: {"friendship":"Дружба","date":"Свидания","relationship":"Отношения","family":"Семья","travels":"Путешествия"},
    appearance: {"thick":"Худощавое","standart":"Обычное","fit":"Спортивное","few_extra_kg":"Пара лишних килограмм"},
    relationship: {"no_relationship":"Нет отношений","married":"В браке","in_relationship":"В отношениях","lost_partner":"Потерял супруга","divorced":"В разводе"},
    education: {"higher":"Высшее","secondary":"Среднее","elementary":"Начальное","academic":"Академическое","incomplete":"Незаконченное"},
    children: {"with_me":"Живут со мной","live_separately":"Живут отдельно","dont_want":"Не хочу детей","later":"Планирую позже"},
    finance: {"looking_for_sponsor":"Ищу спонсора","can_be_sponsor":"Могу быть спонсором","average_income":"Средний доход"},
    alcohol: {"sometimes":"Иногда","regularly":"Регулярно","never":"Никогда"},
    smoking: {"sometimes":"Иногда","regularly":"Регулярно","never":"Никогда"},
    search_gender: {"M":"Мужчину","F":"Женщину","T":"Трансгендера"}
};

const Trait = ({ label, trait, value, last }) => {
    if (traitValues[trait]) {
        value = traitValues[trait][value];
    }
    return (
        <Grid container justify="space-between" style={last ? { marginBottom: 24 } : {}}>
            <Grid item>
                <Typography variant="body2" gutterBottom style={{ marginLeft: 16 }}>
                    {label}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" gutterBottom>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Trait;
