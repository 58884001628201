import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Fab, Typography, Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const FirstStageSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, 'Не валидный телефон!')
    .required('Это поле обязательно!'),
  email: Yup.string()
    .email('Не валидный Email!')
    .required('Это поле обязательно!'),
  password: Yup.string()
    .min(8, 'Минимальная длина пароля 8 символов!')
    .max(32, 'Максимальная длина пароля 32 символа!')
    .required('Это поле обязательно!'),
  repeat_password: Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли не совпадают!')
    .required('Это поле обязательно!'),
});

export default ({ handleNext }) => {
    const [errors, setErrors] = useState(null);

    const onSubmit = async (values) => {
        try {
            await axios.post('/api/users/check/', values);
            handleNext(values);
        } catch (error) {
            setErrors(error.response.data);
        }
    }

    return (
        <div>
            <ValidationErrors errors={errors} />
            <Typography align="center">Данные регистрации</Typography>
            <Formik
                initialValues={{ phone: '', email: '', password: '', repeat_password: '' }}
                validationSchema={FirstStageSchema}
                onSubmit={onSubmit}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            name="phone"
                            type="phone"
                            label="Телефон"
                            size="medium"
                        />
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                        />
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            name="password"
                            type="password"
                            label="Пароль"
                        />
                        <Field
                            className="SignupForm-input"
                            component={TextField}
                            name="repeat_password"
                            type="password"
                            label="Повторите пароль"
                        />
                        <Typography
                            align="center"
                            variant="caption"
                            className="SignupForm-disclaimer"
                        >
                            Продолжая регистрацию, вы даете свое <Typography component="a" target="_blank" href="/static/soglas.pdf" variant="caption">согласие на обработку персональных данных</Typography>. Подтверждаете, что ознакомлены и согласны с условиями использования сервиса и политикой обработки персональных данных.
                        </Typography>
                        <Fab
                            className="SignupForm-submit"
                            size="large"
                            variant="extended"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Продолжить
                        </Fab>
                    </Form>
                )}
            </Formik>
            <LoginLink />
        </div>
    );
};

const LoginLink = () => (
    <>
        <div className="SignupForm-or">
            или
        </div>
        <Button
            color="primary"
            size="large"
            fullWidth
            component={Link}
            to="/auth/signin"
        >
            Войти
        </Button>
    </>
);

const ValidationErrors = ({ errors }) => {
    if (!errors) return null;
    return Object.keys(errors)
        .map(key => errors[key])
        .reduce((prev, curr) => prev.concat(curr))
        .map((error, index) =>
            <Typography
                color="error"
                variant="subtitle2"
                display="block"
                align="center"
                gutterBottom
                key={index.toString()}
                style={{ maxWidth: 320 }}
            >
                {error}
            </Typography>
        );
}
