import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
    Forum as ForumIcon,
    VisibilityOff as VisibilityOffIcon,
    NotInterested as NotInterestedIcon,
    Favorite as FavoriteIcon,
} from '@material-ui/icons';

import logo from '../../assets/logo-white.png';
import { toggleSubscriptionDialog, togglePaymentDialog } from '../../actions/profile';

const Subscription = ({ open, toggleSubscriptionDialog, togglePaymentDialog }) => {
    const classes = useStyles();
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));

    const handlePayment = async () => {
        toggleSubscriptionDialog(false);
        togglePaymentDialog(true);
    }

    return (
        <Dialog
            onClose={() => toggleSubscriptionDialog(false)}
            fullScreen={!upMd}
            open={open}
        >
            <DialogTitle
                disableTypography
                className={classes.DialogTitle}
            >
                <img src={logo} alt="lovapp.ru" />
                <IconButton
                    onClick={() => toggleSubscriptionDialog(false)}
                    className={classes.CloseButton}
                >
                    <CloseIcon color="inherit" style={{ color: '#fff' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" gutterBottom style={{ lineHeight: 1.36 }}>
                            Премиум доступ<br/>
                            <br />
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.subFeature}>
                            <ForumIcon color="inherit" style={{ fontSize: 56, marginBottom: 8 }} />
                            <Typography variant="body1" align="center">Безлимитные сообщения</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.subFeature}>
                            <VisibilityOffIcon color="inherit" style={{ fontSize: 56, marginBottom: 8 }} />
                            <Typography variant="body1" align="center">Больше анкет в поиске</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.subFeature}>
                            <NotInterestedIcon color="inherit" style={{ fontSize: 56, marginBottom: 8 }} />
                            <Typography variant="body1" align="center">Безлимитные диалоги</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.subFeature}>
                            <FavoriteIcon color="inherit" style={{ fontSize: 56, marginBottom: 8 }} />
                            <Typography variant="body1" align="center">Больше симпатий</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Fab
                            onClick={handlePayment}
                            color="default"
                            variant="outlined"
                            className={classes.SubmitButtonOutlined}
                        >
                            Продолжить
                        </Fab>
                        <Typography
                            variant="caption"
                            align="center"
                            component="p"
                            gutterBottom
                        >
                            Стоимость доступа 750 руб. в месяц
                        </Typography>
                        <Fab
                            onClick={handlePayment}
                            color="secondary"
                            variant="extended"
                            className={classes.SubmitButton}
                        >
                            Попробовать за 1р
                        </Fab>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            align="center"
                            component="p"
                            gutterBottom
                            style={{ marginBottom: 24 }}
                        >
                            Продолжая, вы принимаете правила использования сервиса,
                            даете согласие на получение безлимитного доступа к сервису
                            согласно <a href="/static/contractoffer.pdf" target="_blank" style={{ color: 'blue' }}>лицензионному договору</a> и подтверждаете, что вам 18 лет или более.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    DialogContent: {
        background: theme.palette.primary.main,
        color: '#fff'
    },
    DialogTitle: {
        margin: 0,
        textAlign: 'center',
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        background: theme.palette.primary.main,
    },
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    subFeature: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 24
    },
    SubmitButtonOutlined: {
        color: '#fff',
        border: '2px solid #fff',
        background: 'transparent !important',
        boxShadow: 'none !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 300,
        marginBottom: 16,
        marginTop: 36,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    SubmitButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 300,
        marginBottom: 16,
        marginTop: 36,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
}));

const mapStateToProps = ({ profile }) => ({
    open: profile.showSubscriptionDialog
});

export default connect(mapStateToProps, { toggleSubscriptionDialog, togglePaymentDialog })(Subscription);
