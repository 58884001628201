import React from 'react';
import { connect } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { removeImage } from '../../actions/profile';

import './ProfileImages.sass';
import ImageUploader from './ImageUploader';
import ImageRemover from './ImageRemover';

const ProfileImages = ({ imagesArr, remover = false, uploader = false, showTitle = false, centered = false }) => {
    const images = imagesArr.map(img => (
        <Grid item key={img.id}>
            <Image src={img.url} id={img.id} remover={remover} />
        </Grid>
    ));
    return (
        <div>
            {showTitle ? <Typography variant="h6" gutterBottom>Фото</Typography> : null}
            <Grid container spacing={1} justify={centered ? 'center' : 'flex-start'}>
                {images}
                {uploader ? <Grid item><ImageUploader /></Grid> : null}
            </Grid>
        </div>
    );
};

const Image = ({ src, id, remover }) => (
    <div
        className="ProfileImages-image"
        style={{ backgroundImage: `url(${src})` }}
    >
        {remover ? <ImageRemover id={id} /> : null}
    </div>
);

const mapStateToProps = ({ profile }) => ({
    imagesArr: profile.images
});

export default connect(mapStateToProps, { removeImage })(ProfileImages);
