import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
} from '@material-ui/core';

import { cancelSubscription } from '../../actions/profile';

const Subscription = ({ open, isPremium, isCancelSubscription, cancelSubscription }) => {
    const classes = useStyles();
    let [loading, setLoading] = useState(false);
    let [isOpen, setIsOpen] = useState(false);

    return (
        <div style={{ marginLeft: 16, marginTop: 8 }}>
            {isPremium && !isCancelSubscription ? (
                <Button variant="outlined" color="secondary" onClick={() => setIsOpen(true)}>
                    Отменить подписку
                </Button>
            ) : null}
            <Dialog
                onClose={() => setIsOpen(false)}
                open={isOpen}
                disableBackdropClick={loading}
                disableEscapeKeyDown={loading}
            >
                <DialogContent className={classes.DialogContent}>
                    {!isCancelSubscription && !loading ? (
                        <Typography variant="h6" align="center">
                            Отменить подписку?
                        </Typography>
                    ) : null}
                    {isCancelSubscription && !loading ? (
                        <Typography variant="h6" align="center">
                            Подписка отменена!
                        </Typography>
                    ) : null}
                    {loading ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    ) : null}
                </DialogContent>
                {!isCancelSubscription && !loading ? (
                    <DialogActions>
                        <Button color="default" onClick={() => setIsOpen(false)}>
                            Нет
                        </Button>
                        <Button onClick={() => cancelSubscription(setLoading)} color="primary" autoFocus>
                            Да
                        </Button>
                    </DialogActions>
                ) : null}
                {isCancelSubscription && !loading ? (
                    <DialogActions>
                        <Button color="default" onClick={() => setIsOpen(false)}>
                            Закрыть
                        </Button>
                    </DialogActions>
                ) : null}
            </Dialog>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    DialogTitle: {
        margin: 0,
        textAlign: 'center',
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    DialogContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 20,
    },
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    subFeature: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 24
    },
    SubmitButtom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 300,
        marginBottom: 16,
        marginTop: 36,
        marginRight: 'auto',
        marginLeft: 'auto'
    }
}));

const mapStateToProps = ({ profile }) => ({
    isPremium: profile.traits.is_cancel_enable,
    isCancelSubscription: profile.traits.is_cancel_subscription
});

export default connect(mapStateToProps, { cancelSubscription })(Subscription);
