import { v4 as uuid } from 'uuid';
import moment from 'moment';

import {
    CLEAR_CHAT,
    LOAD_CHAT_LIST,
    SELECT_CHAT,
    LOAD_MESSAGES,
    SET_FORM_HEIGHT,
    SEND_MESSAGE,
    RECEIVE_MESSAGE,
    NEW_CHAT,
    PEER_MARK_READ,
    TOGGLE_BANNED_DIALOG,
} from '../actions/types';
import NotificationSound from '../helpers/soundNotification';

const INITIAL_STATE = {
    chats: null,
    current: null,
    formHeight: 88,
    showBannedDialog: false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case CLEAR_CHAT: {
            return {
                chats: null,
                current: null,
                formHeight: 88
            };
        }

        case LOAD_CHAT_LIST: {
            const chats = action.payload.map(chat => {
                chat.messagesLoaded = false;
                return chat;
            });
            return {
                ...state,
                chats
            };
        }

        case SELECT_CHAT: {
            const chats = state.chats.filter(chat => !chat.temporary);
            const currentChat = chats.find(chat => chat.id === action.payload);
            if (currentChat) {
                currentChat.unread = false;
            }

            return {
                ...state,
                current: action.payload,
                chats,
            };
        }

        case LOAD_MESSAGES: {
            const chats = [...state.chats];
            const chat = chats[chats.findIndex(item => item.peer_user.id === action.payload.id)];

            chat.messages = chat.messagesLoaded ? [...action.payload.messages.reverse(), ...chat.messages] : [...action.payload.messages.reverse()];
            chat.messagesLoaded = true;
            chat.messagesNext = action.payload.next;
            return {
                ...state,
                chats
            };
        }

        case SET_FORM_HEIGHT: {
            return {
                ...state,
                formHeight: action.payload
            };
        }

        case SEND_MESSAGE: {
            const message = action.payload;
            const chats = [...state.chats];
            const currentChat = chats.find(item => item.peer_user.id === message.to_user_id);

            message.id = uuid();
            message.created_at = moment().format('DD.MM HH:mm');
            currentChat.last_message = message;
            currentChat.messages = currentChat.messages ? [...currentChat.messages, message] : [message];

            return {
                ...state,
                chats
            };
        }

        case RECEIVE_MESSAGE: {
            const { message, currentUserId, socket } = action.payload;
            let chats = [...state.chats];
            const currentChat = currentUserId === message.from_user_id
                ? chats.find(item => item.peer_user.id === message.to_user_id)
                : chats.find(item => item.peer_user.id === message.from_user_id);
            let current = state.current;

            if (currentChat) {
                // Asign real id to current if it is active at the moment
                if (
                    currentChat.temporary
                    && currentChat.peer_user.id === message.peer_dialog.peer_user.id
                    && currentChat.id === current
                ) {
                    current = message.peer_dialog.id
                }
                // Asign real id to temporary chat
                currentChat.id = currentChat.temporary ? message.peer_dialog.id : currentChat.id;
                // Make chat persistent if it is temporary
                currentChat.temporary = false;

                // Add message to the chat
                currentChat.last_message = message;
                currentChat.messages = currentChat.messagesLoaded ? [...currentChat.messages, message] : null;
                currentChat.unread = currentChat.id === current ? false : true;

                // Move chat to the top
                chats = chats.filter(chat => chat.id !== currentChat.id);
                chats.unshift(currentChat);

                // Play notification sound
                if (currentChat.id !== current && currentChat.peer_user.id !== message.to_user_id) {
                    NotificationSound.play();
                }

                // Mark message as read
                if (current === message.peer_dialog.id) {
                    socket.emit('mark_read', { peer_user_id: currentChat.peer_user.id });
                }
            } else if (message.peer_dialog) {
                chats.unshift(message.peer_dialog);
                chats[0].messages = [message];
                chats[0].last_message = message;
                chats[0].unread = true;
            }

            return {
                ...state,
                current,
                chats
            };
        }

        case NEW_CHAT: {
            const chats = state.chats.filter(chat => !chat.temporary);
            const chatIndex = chats.findIndex(chat => chat.peer_user.id === action.payload.peer_user.id);
            let current = action.payload.id;

            if (chatIndex === -1) {
                chats.unshift(action.payload);
            } else {
                current = chats[chatIndex].id;
            }

            return {
                ...state,
                current,
                chats
            };
        }

        case PEER_MARK_READ: {
            const chats = [...state.chats];
            const currentChat = chats.find(item => item.id === action.payload.id);

            currentChat.peer_last_seen_at = action.payload.time

            return {
                ...state,
                chats
            };
        }

        case TOGGLE_BANNED_DIALOG: {
            return {
                ...state,
                showBannedDialog: action.payload
            };
        }

        default:
            return state;
    }
};

