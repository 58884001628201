import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Fab, CircularProgress, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import './InitialPhotoUpload.sass';
import ProfileImages from '../profile/ProfileImages';

import './Main.sass';
import { loadProfile } from '../../actions/profile';

const InitialPhotoUpload = ({ profileLoaded, loadProfile, history }) => {
    useEffect(() => {
        if (!profileLoaded) loadProfile();
    }, [profileLoaded, loadProfile]);

    return (
        <div className="InitialPhotoUpload-container">
            {profileLoaded ? (
                <div className="InitialPhotoUpload">
                    <Typography
                        align="center"
                        variant="h5"
                        gutterBottom
                        style={{ marginBottom: 24 }}
                    >
                        Загрузите фото
                    </Typography>
                    <ProfileImages uploader remover centered />
                    <Fab
                        size="large"
                        variant="extended"
                        color="secondary"
                        className="SignupForm-finish-btn"
                        onClick={() => history.push('/search')}
                        style={{ width: 320, marginTop: 48 }}
                    >
                        Начать знакомства
                    </Fab>
                </div>
            ) : (
                <div className="InitialPhotoUpload InitialPhotoUpload--loading">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ profile }) => ({
    profileLoaded: profile.traits && profile.questions && profile.images ? true : false,
});

export default connect(mapStateToProps, { loadProfile })(withRouter(InitialPhotoUpload));
