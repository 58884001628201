import { v4 as uuid } from 'uuid';

import api from '../api';
import {
    ADD_ERROR,
    LOAD_CHAT_LIST,
    SELECT_CHAT,
    LOAD_MESSAGES,
    RECEIVE_MESSAGE,
    NEW_CHAT,
    TOGGLE_SUBSCRIPTION_DIALOG,
    TOGGLE_BANNED_DIALOG,
    PEER_MARK_READ,
} from './types';

// Load list of chats
export const loadChatList = () => async dispatch => {
    try {
        const response = await api.get('/api/chat/user_dialogs/');

        dispatch({ type: LOAD_CHAT_LIST, payload: response.data.results });
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { loadChatList: error.response.data } });
    }
}

// Select chat
export const selectChat = (id, socket) => (dispatch, getState) => {
    if (socket) {
        const state = getState();
        const chat = state.chat.chats[state.chat.chats.findIndex(item => item.id === id)];
        socket.emit('mark_read', { peer_user_id: chat.peer_user.id });
    }
    dispatch({ type: SELECT_CHAT, payload: id });
}

// Load messages
export const loadMessages = id => async (dispatch, getState) => {
    try {
        const state = getState();
        const chat = state.chat.chats[state.chat.chats.findIndex(item => item.peer_user.id === id)];
        const url = chat.messagesNext ? chat.messagesNext.replace(/^.*\/\/[^/]+/, '') : `/api/chat/messages/${id}/`;
        const response = await api.get(url);
        dispatch({ type: LOAD_MESSAGES, payload: {
            id: id,
            next: response.data.next,
            messages: response.data.results
        }});
    } catch (error) {
        dispatch({ type: SELECT_CHAT, payload: null });
        dispatch({ type: ADD_ERROR, payload: { loadMessages: error.response.data } });
    }
}

// New chat
export const newChat = user => async (dispatch) => {
    const chat = {
        id: uuid(),
        temporary: true,
        peer_user: user,
        unread: false,
        last_message: null,
        messages: []
    };
    dispatch({ type: NEW_CHAT, payload: chat });
}

// Send message
export const sendMessage = (socket, message) => async () => {
    try {
        await socket.emit('message', message);
    } catch (error) {
        console.log(error);
    }
}

// Peer mark read
export const peerMarkRead = msg => dispatch => {
    dispatch({
        type: PEER_MARK_READ,
        payload: {
            id: msg.dialog_id,
            time: msg.time
        }
    });
}

// Receive message
export const receiveMessage = (message, socket) => async (dispatch, getState) => {
    const state = getState();
    if (typeof message === 'object') {
        if (!message.error) {
            dispatch({
                type: RECEIVE_MESSAGE,
                payload: {
                    socket,
                    message,
                    currentUserId: state.profile.traits.id,
                }
            });
        } else if (message.error === 'is_block') {
            dispatch({ type: TOGGLE_BANNED_DIALOG, payload: true });
        } else {
            dispatch({ type: TOGGLE_SUBSCRIPTION_DIALOG, payload: true });
        }
    }
}

// Toggle banned dialog
export const toggleBannedDialog = value => async dispatch => {
    dispatch({ type: TOGGLE_BANNED_DIALOG, payload: value });
}

