import React, { useState } from 'react';
import { Button, Typography, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    Button: {
        textTransform: 'none',
    }
})

export default ({ title, question, choices, handleNext }) => {
    const classes = useStyles();
    const [current, setCurrent] = useState(null);

    const renderChoices = Object.keys(choices).map(key => (
            <Button
                key={question + key}
                className="SignupForm-choice"
                variant="contained"
                color={key === current ? 'primary' : 'default'}
                classes={{ root: classes.Button }}
                fullWidth
                disableElevation
                onClick={() => setCurrent(key)}
            >
                {choices[key]}
            </Button>
    ));

    return (
        <div className="SignupForm-stage">
            <Typography
                gutterBottom
                align="center"
                variant="subtitle1"
                style={{ marginBottom: 8 }}
            >
                {title}
            </Typography>
            {renderChoices}
            <Fab
                className="SignupForm-submit"
                variant="extended"
                color="secondary"
                disabled={!current}
                onClick={() => {
                    handleNext({ [question]: current });
                    setCurrent(null);
                }}
            >
                Продолжить
            </Fab>
        </div>
    );
};
