import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default ({ size = 'full' }) => {
    return (
        <div style={{ color: '#b1b1b1', textAlign: 'center', margin: '16px 0px' }} className="SiteInfo">
            {size === 'full' ? (
                <>
                    <Typography
                        variant="caption"
                    >
                        {new Date().getFullYear()} © LOVA 
                    </Typography>
                    &nbsp;|&nbsp;
                </>
            ) : null}
            <Typography
                variant="caption"
                component={Link}
                to="/about"
                style={{ color: '#b1b1b1' }}
            >
                О сервисе
            </Typography>
            &nbsp;|&nbsp;
            <Typography
                variant="caption"
                component="a"
                target="_blank"
                href="/static/privatepolicy.pdf"
                style={{ color: '#b1b1b1' }}
            >
                Политика конфиденциальности
            </Typography>
            {size !== 'double-line' ? '\xa0|\xa0' : <br/>}
            <Typography
                variant="caption"
                component="a"
                target="_blank"
                href="/static/contractoffer.pdf"
                style={{ color: '#b1b1b1' }}
            >
                Договор оферты
            </Typography>
            &nbsp;|&nbsp;
            <Typography
                variant="caption"
                component="a"
                target="_blank"
                href="mailto:manager@lovapp.ru"
                style={{ color: '#b1b1b1' }}
            >
                Задать вопрос
            </Typography>
        </div>
    );
}
