import React, { useState, useEffect } from 'react';
import { Grid, Container, Hidden, LinearProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import io from 'socket.io-client';

import './Main.sass';
import { loadFromStorage } from '../../helpers/localStorage';
import { loadProfile } from '../../actions/profile';
import { loadChatList, receiveMessage, peerMarkRead } from '../../actions/chat';
import Error from '../Error';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Search from '../search/Search';
import Messenger from '../chat/Messenger';
import Profile from '../profile/Profile';
import EditProfile from '../profile/EditProfile';
import SearchFilter from '../search/SearchFilter';
import SubscriptionDialog from '../profile/SubscriptionDialog';
import PaymentDialog from '../profile/PaymentDialog';
import BannedDialog from '../chat/BannedDialog';


const Main = ({ profileLoaded, chatLoaded, loadProfile, loadChatList, receiveMessage, peerMarkRead }) => {
    const [socket, setSocket] = useState(null);
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        let newSocket;
        if (!profileLoaded) loadProfile();
        if (!chatLoaded) loadChatList();
        if (!socket) {
            newSocket = io.connect('/users', {
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            'Authorization': `Bearer ${loadFromStorage('auth').token}`
                        }
                    }
                }
            });
            newSocket.on('message', msg => receiveMessage(msg, newSocket));
            newSocket.on('peer_mark_read', msg => peerMarkRead(msg));
            setSocket(newSocket);
        };
        return () => {
            if (newSocket) {
                newSocket.disconnect();
            }
        };
    // eslint-disable-next-line
    }, [loadChatList, loadProfile, receiveMessage]);

    if (!profileLoaded || !chatLoaded || !socket) return <LinearProgress />;
    return (
        <div className="Main">
            <Header />
            <SearchFilter />
            <SubscriptionDialog />
            <PaymentDialog />
            <BannedDialog />
            <Error />
            <Container disableGutters={!upMd}>
                <Grid container spacing={4}>
                    <Hidden smDown>
                        <Grid item md={3}>
                            <Sidebar />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={9}>
                        <Switch>
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/profile/edit" component={EditProfile} />
                            <Route exact path="/search" component={Search} />
                            <Route exact path="/chat" component={() => <Messenger socket={socket} />} />
                        </Switch>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const mapStateToProps = ({ profile, chat }) => ({
    profileLoaded: profile.traits && profile.questions && profile.images ? true : false,
    chatLoaded: chat.chats ? true : false
});

export default connect(mapStateToProps, { loadProfile, loadChatList, receiveMessage, peerMarkRead })(Main);
