import React from 'react';
import "moment/locale/ru";
import { Field } from 'formik';
import { Typography, Grid, MenuItem } from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default ({ label, type, name, variants, values }) => {
    type = type ? type : 'Select';
    let UniversalField;
    switch (type) {
        case 'TextField':
            UniversalField = TraitTextField;
            break;
        case 'Select':
            UniversalField = TraitSelectField;
            break;
        case 'DatePicker':
            UniversalField = TraitDatePickerField;
            break;
        case 'Country':
            UniversalField = TraitCoutryField;
            break;
        case 'City':
            UniversalField = TraitCityField;
            break;
        default:
            UniversalField = TraitSelectField;
    }
    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>
                <Typography variant="body1" style={{ marginLeft: 16 }}>
                    {label}
                </Typography>
            </Grid>
            <Grid item>
                <UniversalField name={name} variants={variants} values={values} />
            </Grid>
        </Grid>
    );
}

const TraitSelectField = ({ name, variants }) => (
    <Field
        name={name}
        component={Select}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ marginBottom: 4, marginTop: 4 }}
    >
        {Object.keys(variants).map(key => <MenuItem value={key} key={key}>{variants[key]}</MenuItem>)}
    </Field>

);

const TraitTextField = ({ name }) => (
    <Field
        name={name}
        component={TextField}
        variant="outlined"
        size="small"
        margin="dense"
        style={{ marginBottom: 4, marginTop: 4 }}
    />
);

const TraitDatePickerField = ({ name }) => (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="ru" >
        <Field
            inputVariant="outlined"
            size="small"
            component={DatePicker}
            openTo="year"
            format="YYYY/MM/DD"
            views={["year", "month", "date"]}
            disableFuture
            invalidDateMessage="Не правильный формат даты"
            name="birthday"
            style={{ marginBottom: 4, marginTop: 4 }}
        />
    </MuiPickersUtilsProvider>
);

const TraitCoutryField = ({ variants }) => (
    <Field
        component={Select}
        name="country"
        variant="outlined"
        size="small"
        margin="dense"
        style={{ marginBottom: 4, marginTop: 4 }}
    >
        {variants.map(country => <MenuItem value={country.id} key={country.id}>{country.name}</MenuItem>)}
    </Field>
);

const TraitCityField = ({ values, variants }) => (
    <Field
        component={Select}
        name="city"
        fullWidth
        variant="outlined"
        size="small"
        margin="dense"
        style={{ marginBottom: 4, marginTop: 4 }}
    >
            {
                values.country && variants
                    [variants.findIndex(element => element.id === values.country)]
                    .cities
                    .map(city => <MenuItem value={city.id} key={city.id}>{city.name}</MenuItem>)
            }
    </Field>
);
