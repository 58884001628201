import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
} from '@material-ui/icons';

import { toggleBannedDialog } from '../../actions/chat';

const BannedDialog = ({ open, toggleBannedDialog }) => {
    const classes = useStyles();
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Dialog
            onClose={() => toggleBannedDialog(false)}
            fullScreen={!upMd}
            open={open}
        >
            <DialogTitle
                disableTypography
                className={classes.DialogTitle}
            >
                <IconButton
                    onClick={() => toggleBannedDialog(false)}
                    className={classes.CloseButton}
                >
                    <CloseIcon color="inherit" style={{ color: '#fff' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" align="center" gutterBottom style={{ lineHeight: 1.36 }}>
                            Ваш аккаунт заблокирован
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    DialogContent: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 400,
        paddingBottom: theme.spacing(6),
        background: theme.palette.error.dark,
        color: '#fff',
    },
    DialogTitle: {
        textAlign: 'center',
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        background: theme.palette.error.dark,
    },
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    subFeature: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 24
    },
    SubmitButtom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 300,
        marginBottom: 16,
        marginTop: 36,
        marginRight: 'auto',
        marginLeft: 'auto'
    }
}));

const mapStateToProps = ({ chat }) => ({
    open: chat.showBannedDialog
});

export default connect(mapStateToProps, { toggleBannedDialog })(BannedDialog);
