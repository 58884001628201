import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
    CircularProgress,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import './SignupForm.sass';
import { signUp } from '../../actions';
import logoUrl from '../../assets/logo-red.png';
import FirstStage from './stages/FirstStage';
import SecondStage from './stages/SecondStage';
import ThirdStage from './stages/ThirdStage';
import ForthStage from './stages/ForthStage';
import FifthStage from './stages/FifthStage';
import AnimatedPhotos from './AnimatedPhotos';
import SiteInfo from '../SiteInfo';

const SignupForm = ({ utm, signUp, history }) => {
    const theme = useTheme();
    const deviceType = useMediaQuery(theme.breakpoints.down('sm')) ? 'web_mob' : 'web_desk';
    const [questions, setQuestions] = useState(null);
    const [answers, setAnswers] = useState({});
    const [current, setCurrent] = useState(null);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        (async () => {
            const response = await axios.get('/api/users/choice_list/');
            setQuestions(response.data);
            setCurrent(0);
        })();
    }, []);

    // Cycle throught questions
    const handleNext = (data) => {
        setCurrent(current + 1);
        setAnswers({ ...answers, ...data });
        setErrors(null);
    };

    // Send the registration form to the api
    const handleSubmit = async () => {
        try {
            setCurrent(100);
            const response = await axios.post('/api/users/registration/', {
                ...answers,
                utm,
                reg_platform: deviceType
            });
            signUp({
                token: response.data.access,
                refresh: response.data.refresh
            }, history);
            window.gtag && window.gtag('event', 'conversion', {'send_to': 'AW-689533777/18QiCNmyxNcCENHm5cgC'});
        } catch (error) {
            console.log(error.response)
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            }
            setCurrent(0);
        }
    }
    return (
        <div className="SignupForm-container">
            <div className="SignupForm">
                <img className="SignupForm-logo" src={logoUrl} alt="" />
                <FormErrors errors={errors} />
                {renderQuestions(questions, current, handleNext, handleSubmit)}
                <SiteInfo size="double-line" />
            </div>
            {current === 0 ? <AnimatedPhotos /> : null}
        </div>
    )
}

const renderQuestions = (questions, current, handleNext, handleSubmit) => {
    if (!questions) return <div className="SignupForm-loader"><CircularProgress /></div>;
    switch (current) {
        case 0: return <FirstStage questions={questions} handleNext={handleNext} />;
        case 1: return <SecondStage questions={questions} handleNext={handleNext} />;
        case 2: return <ThirdStage questions={questions} handleNext={handleNext} />;
        case 3: return <ForthStage questions={questions} handleNext={handleNext} />;
        case 4: return <FifthStage
            title="Ваш пол"
            question="gender"
            choices={questions.gender}
            handleNext={handleNext}
        />;
        case 5: return <FifthStage
            title="Хочу найти"
            question="search_gender"
            choices={questions.search_gender}
            handleNext={handleNext}
        />;
        case 6: return <FifthStage
            title="Цель поиска"
            question="search_purpose"
            choices={questions.search_purpose}
            handleNext={handleNext}
        />;
        case 7: return <FifthStage
            title="Ваше образование"
            question="education"
            choices={questions.education}
            handleNext={handleNext}
        />;
        case 8: return <FifthStage
            title="Жилье"
            question="housing"
            choices={questions.housing}
            handleNext={handleNext}
        />;
        case 9: return <FifthStage
            title="Как вы выглядите"
            question="appearance"
            choices={questions.appearance}
            handleNext={handleNext}
        />;
        case 10: return <FifthStage
            title="Дети"
            question="children"
            choices={questions.children}
            handleNext={handleNext}
        />;
        case 11: return <FifthStage
            title="Отношения"
            question="relationship"
            choices={questions.relationship}
            handleNext={handleNext}
        />;
        case 12: return <FifthStage
            title="Финансовое положение"
            question="finance"
            choices={questions.finance}
            handleNext={handleNext}
        />;
        case 13: return <FifthStage
            title="Алкоголь"
            question="alcohol"
            choices={questions.alcohol}
            handleNext={handleNext}
        />;
        case 14: return <FifthStage
            title="Курение"
            question="smoking"
            choices={questions.smoking}
            handleNext={handleNext}
        />;
        case 15: {
            handleSubmit();
            return <div className="SignupForm-loader"><CircularProgress /></div>;
        }
        case 100: return <div className="SignupForm-loader"><CircularProgress /></div>;
        default: return null;
    }
};

const FormErrors = ({ errors }) => {
    if (!errors) return null;
    return Object.keys(errors)
        .map(key => errors[key])
        .reduce((prev, curr) => prev.concat(curr))
        .map((error, index) =>
            <Typography
                color="error"
                variant="subtitle2"
                display="block"
                align="center"
                gutterBottom
                key={index.toString()}
                style={{ maxWidth: 320 }}
            >
                {error}
            </Typography>
        );
}

const mapStateToProps = ({ auth })  => ({
    utm: auth.utm
});

export default connect(mapStateToProps, { signUp })(withRouter(SignupForm));
