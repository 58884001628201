import axios from 'axios';

import { saveToStorage, loadFromStorage } from '../helpers/localStorage';

import {
    SIGN_IN,
    SIGN_OUT,
    REFRESH_TOKEN,
    SET_UTM,
    ADD_ERROR,
    REMOVE_ERROR,
    CLEAR_PROFILE,
    CLEAR_CHAT,
    CLEAR_SEARCH,
    CLEAR_ERRORS,
} from './types';

// Sign out
export const signOut = () => dispatch => {
    saveToStorage('auth', {});
    saveToStorage('searchParams', null);
    dispatch({ type: SIGN_OUT });
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: CLEAR_CHAT });
    dispatch({ type: CLEAR_SEARCH });
    dispatch({ type: CLEAR_ERRORS });
}

// Sign in
export const signIn = formValues => async dispatch => {
    try {
        const response = await axios.post('/api/token/', formValues);

        const expires = new Date().getTime() + 60 * 60 * 24 * 1000 * 24;
        const refreshExpires = new Date().getTime() + (60 * 60 * 24 * 1000 * 24 * 6);
        const data = {
            isSignedIn: true,
            token: response.data.access,
            refresh: response.data.refresh,
            expires: expires,
            refreshExpires: refreshExpires
        };

        dispatch({ type: SIGN_IN, payload: data });
        dispatch({ type: CLEAR_SEARCH });
        dispatch({ type: REMOVE_ERROR, payload: 'signIn' });
        saveToStorage('auth', data);
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { signIn: error.response.data } });
    }
}

// Sign up
export const signUp = ({ token, refresh }, history) => async dispatch => {
    const expires = new Date().getTime() + 60 * 60 * 24 * 1000 * 24;
    const refreshExpires = new Date().getTime() + (60 * 60 * 24 * 1000 * 24 * 6);
    const data = {
        isSignedIn: true,
        token,
        refresh,
        expires: expires,
        refreshExpires: refreshExpires,
    };
    saveToStorage('auth', data);

    dispatch({
        type: SIGN_IN,
        payload: data
    });
    dispatch({ type: CLEAR_SEARCH });
    history.push('/photo-upload');
}

// Refresh token
export const refresh = (data) => {
    // Write to localStorage
    saveToStorage('auth', { ...loadFromStorage('auth'), ...data });

    return {
        type: REFRESH_TOKEN,
        payload: {
            token: data.token,
            expires: new Date().getTime() + 60 * 60 * 24 * 1000 * 24
        }
    };
}

// Set UTMs
export const setUTM = (data) => {
    saveToStorage('utm', data);
    return {
        type: SET_UTM,
        payload: data
    };
}

// Set error
export const setError = (type, value) => dispatch => {
    dispatch({ type: ADD_ERROR, payload: { [type]: value } });
}
