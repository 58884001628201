import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, IconButton, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { Close as DeleteIcon } from '@material-ui/icons';

import { removeImage } from '../../actions/profile';

const ImageRemover = ({ removeImage, id }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <IconButton
                size="small"
                color="inherit"
                className="ProfileImages-image_delete"
                onClick={handleClickOpen}
            >
                <DeleteIcon className="ProfileImages-image_icon"/>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Удалить фото?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Отмена
                    </Button>
                    <Button
                        onClick={() => removeImage(id)}
                        color="primary"
                        autoFocus
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(null, { removeImage })(ImageRemover);
