import React from 'react';
import { IconButton, Avatar, useMediaQuery } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import Slider from "react-slick";

import './ImageSlider.sass';

const ImageSlider = ({ images, userId }) => {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    return (
        <Slider
            key={userId}
            dots={true}
            infinite={true}
            swipeToSlide={true}
            className="ImageSlider"
            dotsClass="ImageSlider-dots"
            prevArrow={
                <IconButton
                    size="small"
                    classes={{ root: classes.ArrowPrev }}
                >
                    <ChevronLeft style={{ fontSize: 56 }} />
                </IconButton>
            }
            nextArrow={
                <IconButton
                    size="small"
                    classes={{ root: classes.ArrowNext }}
                >
                    <ChevronRight style={{ fontSize: 56 }} />
                </IconButton>
            }
            customPaging={() => (
                <div className="ImageSlider-dot" />
            )}
        >
            {!images.length ? <Avatar variant="square" className={classes.Avatar} /> : null}
            {images.map(img => <Slide img={img} key={img.id} upMd={upMd} />)}
        </Slider>
    );
};

const Slide = ({ img, upMd }) => (
    <div 
        style={{
            background: `no-repeat center/cover url(${upMd ? img.thumbnail_url : img.thumbnail_url})`,
            height: '100%',
            width: '100%'
        }}
    />
);

const useStyles = makeStyles((theme) => ({
    ArrowPrev: {
        position: 'absolute',
        top: 'calc(50% - 30px)',
        left: theme.spacing(2),
        zIndex: 1,
        color: '#fff'
    },
    ArrowNext: {
        position: 'absolute',
        top: 'calc(50% - 30px)',
        right: theme.spacing(2),
        zIndex: 1,
        color: '#fff'
    },
    Avatar: {
        height: '100%',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default ImageSlider;
