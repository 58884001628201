import axios from 'axios';

import { saveToStorage } from '../helpers/localStorage';
import api from '../api';

import {
    SET_SEARCH_LOADING,
    SET_SEARCH_PARAMS,
    TOGGLE_FILTER,
    LOAD_USERS,
    SKIP_USER,
    ADD_ERROR,
} from './types';

// Set params
export const setSearchParams = (params, setSubmitting) => async dispatch => {
    dispatch({
        type: SET_SEARCH_PARAMS,
        payload: params
    });
    dispatch(loadUsers());
    saveToStorage('searchParams', params);
    setSubmitting(false);
}

// Toggle filter visibility
export const toggleFilter = filterOpen => async dispatch => {
    dispatch({
        type: TOGGLE_FILTER,
        payload: filterOpen
    });
}

// Load users without authorization
export const loadUsersAnonymously = () => async dispatch => {
    dispatch({ type: SET_SEARCH_LOADING, payload: true });
    try {
        const response = await axios.get('/api/users/public_search/');

        dispatch({
            type: LOAD_USERS,
            payload: {
                results: response.data.results,
                nextPage: response.data.next
            }
        });
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { loadUsers: error.response.data } });
    }
}

// Load users
export const loadUsers = () => async (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_LOADING, payload: true });
    try {
        const { search, profile } = getState();
        const url = search.nextPage ? search.nextPage : '/api/users/search/';
        let params;
        if (search.params && !search.nextPage) {
            params = {
                ...search.params,
                is_online: search.params.is_online || undefined,
                gender: search.params.gender === 'ALL'? undefined : search.params.gender,
                search_purpose: search.params.search_purpose === 'any' ? undefined : search.params.search_purpose
            };
        }
        
        if (search.params === null) {
            params = {
                gender: profile.traits.search_gender,
            };
        }

        const response = await api.get(url, { params });

        dispatch({
            type: LOAD_USERS,
            payload: {
                results: response.data.results,
                nextPage: response.data.next
            }
        });
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { loadUsers: error.response.data } });
    }
}

// Skip user
export const skipUser = () => (dispatch, getState) => {
    const { search } = getState();
    if (search.current + 1 < search.users.length) {
        dispatch({ type: SKIP_USER });
    } else {
        dispatch(loadUsers());
    }
}
