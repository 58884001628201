import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    LinearProgress,
    Container,
} from '@material-ui/core';
import {
    ArrowBack as BackIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import logoUrl from '../../assets/logo-red.png';

const About = () => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        (async () => {
            if (content) return;
            try {
                const response = await axios.get('/api/users/about_service/1/');
                setContent(response.data.about_service);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [content]);

    if (!content) return <LinearProgress />;
    return (
        <div className="Home">
            <AppBar color="inherit" elevation={1} position={"static"}>
                <Container>
                    <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
                        <IconButton
                            edge="start"
                            component={Link}
                            to="/"
                        >
                            <BackIcon />
                        </IconButton>
                        <img src={logoUrl} alt="lovapp.ru" />
                        <div style={{ width: 36 }} />
                    </Toolbar>
                </Container>
            </AppBar>
            <Container>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div style={{ margin: '36px 0px' }} dangerouslySetInnerHTML={{ __html: content }}></div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default About;
