import React from 'react';
import {
    Grid,
    Container,
    Typography,
} from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

const PaymentSuccess = () => {
    return (
        <div className="Home">
            <Container>
                <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: 'center' }}>
                        <CheckCircleIcon
                            fontSize='inherit'
                            color="primary"
                            style={{ display: 'inline', margin: 'auto', fontSize: 80 }}
                        />
                        <Typography variant="h4" align="center" gutterBottom>
                            Оплата прошла успешно!
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default PaymentSuccess;
