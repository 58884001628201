import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { AddOutlined as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { uploadImage } from '../../actions/profile';

const useStyles = makeStyles({
    UploadButton: {
        width: 175,
        height: 200,
        border: '3px dashed #eee'
    }
});

const ImageUploader = ({ uploadImage }) => {
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    return (
        <div>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="profile-upload-button"
                type="file"
                onChange={(event) => uploadImage(event, setLoading)}
                disabled={loading}
            />
            <label htmlFor="profile-upload-button">
                <Button
                    classes={{ root: classes.UploadButton }}
                    className="ProfileImages_uploader"
                    size="large"
                    color="primary"
                    component="span"
                    disabled={loading}
                >
                    {
                        loading
                        ? <CircularProgress />
                        : <AddIcon fontSize="large"/>
                    }
                </Button>
            </label>
        </div>
    );
}

export default connect(null, { uploadImage })(ImageUploader);
