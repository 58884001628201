import React from 'react';
import {
    AppBar,
    Button,
    Toolbar,
    IconButton,
    Grid,
    Container,
    Typography,
} from '@material-ui/core';
import {
    ArrowBack as BackIcon,
    Error as ErrorIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import logoUrl from '../../assets/logo-red.png';

const PaymentFail = () => {
    return (
        <div className="Home">
            <AppBar color="inherit" elevation={1} position={"static"}>
                <Container>
                    <Toolbar disableGutters={true} style={{ justifyContent: 'space-between' }}>
                        <IconButton
                            edge="start"
                            component={Link}
                            to="/"
                        >
                            <BackIcon />
                        </IconButton>
                        <img src={logoUrl} alt="lovapp.ru" />
                        <div style={{ width: 36 }} />
                    </Toolbar>
                </Container>
            </AppBar>
            <Container>
                <Grid container justify="center" alignItems="center" style={{ height: 'calc(100vh - 64px)' }}>
                    <Grid item xs={12} sm={12} md={12} xl={12} style={{ textAlign: 'center' }}>
                        <ErrorIcon
                            fontSize='inherit'
                            color="error"
                            style={{ display: 'inline', margin: 'auto', fontSize: 80 }}
                        />
                        <Typography variant="h4" align="center">
                            Ошибка!
                        </Typography>
                        <Typography variant="body1" align="center" gutterBottom style={{ marginBottom: 16 }}>
                            Что-то пошло не так... Повторите попытку или попробуйте другую карту.
                        </Typography>
                        <Button
                            component={Link}
                            variant="outlined"
                            color="primary"
                            to="/profile"
                            style={{ display: 'inline-block' }}
                        >
                            Личный кабинет
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default PaymentFail;
