import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import api from '../../api';
import axios from 'axios';

const PaymentWidget = ({ inAppPayment, showPaymentFormLoading, togglePaymentFormLoading }) => {
    const classes = useStyles();
    const [error, setError] = useState(null);
    const [widget, setWidget] = useState(null);
    const [initialized, setInitialized] = useState(false);


    const tokenize = async () => {
        try {
            if (inAppPayment) {
                const user_id = (new URLSearchParams(window.location.search)).get('user_id');
                if (!user_id) {
                    setError('Произошла ошибка. Попробуйте позже. (no user_id)');
                    togglePaymentFormLoading(false);
                    throw new Error('No user_id in URLSearchParams');
                }
                let response = await axios.post('/api/payments/app_widjet_confirmation_token/', { user_id });
                return {
                    token: response.data.payment.confirmation.confirmation_token,
                    paymentId: response.data.uuid4_id
                }
            } else {
                let response = await api.post('/api/payments/widjet_confirmation_token/');
                return {
                    token: response.data.payment.confirmation.confirmation_token,
                    paymentId: response.data.uuid4_id
                }
            }
        } catch {
            setError('Что-то пошло не так... Повторите попытку или попробуйте другую карту.');
        }
    }

    const renderForm = async () => {
        const tokens = await tokenize();
        if (tokens) {
            const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: tokens.token,
                return_url: `https://lovapp.ru/payment-result/?id=${tokens.paymentId}`,
                customization: {
                    payment_methods: ['bank_card'],
                    colors: {
                        controlPrimary: '#fa0401',
                        controlPrimaryContent: '#ffffff',
                    },
                },
                error_callback: function(error) {
                    // Handle initialization errors
                }
            });
            setWidget(checkout);

            checkout.render('payment-form')
                .then(() => {
                    // Code to be executed after form was rendered
                });
        }
        setInitialized(true);
    }

    useEffect(() => {
        (async () => await renderForm())();
        return () => widget && widget.destroy();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {error && <Alert severity="error" style={{ marginBottom: 16 }}>{error}</Alert>}
            {!initialized && <div className={classes.LoaderContainer}><CircularProgress color="secondary"/></div>}
            <div id="payment-form" className={classes.FormContainer}></div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    FormContainer: {
        maxWidth: 400,
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            maxWidth: 500,
            minWidth: 460,
        }
    },
    LoaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 300,
        padding: '16px 64px 48px',
    },
}));

export default PaymentWidget;
