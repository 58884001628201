import React from 'react';
import { Grid, Typography, IconButton, Divider } from '@material-ui/core';
import { EditOutlined as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './Profile.sass';
import generateLocation from '../../helpers/generateLocation';
import Trait from './Trait';
import ProfileImages from './ProfileImages';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';

const Profile = ({ traits, images, questions }) => {
    const classes = useStyles();

    if (!traits || !questions) return null;
    return (
        <div className="Profile">
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid container justify="space-between" style={{ marginTop: 24 }} className={classes.TraitBox}>
                        <Grid item>
                            <Typography variant="h6" gutterBottom style={{ fontSize: 26 }}>{traits.name}, {traits.age}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton component={Link} to="/profile/edit" edge="end">
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={images.length ? {} : { display: 'none' }}>
                    <Grid container className={classes.TraitBox}>
                        <Grid item xs={12}>
                            <ProfileImages showTitle />
                        </Grid>
                    </Grid>
                 </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container className={classes.TraitBox}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Личные данные</Typography>
                            <Trait label="Имя" value={traits.name} />
                            <Trait label="Возраст" value={traits.age} />
                            <Trait label="Внешность" trait="appearance" value={traits.appearance} />
                            <Trait label="Вес" value={traits.weight} />
                            <Trait label="Рост" value={traits.height} />
                            <Trait label="Отношения" trait="relationship" value={traits.relationship} />
                            <Trait label="Образование" trait="education" value={traits.education} />
                            <Trait label="Дети" trait="children" value={traits.children} />
                            <Trait label="Доход" trait="finance" value={traits.finance} />
                            <Trait label="Алкоголь" trait="alcohol" value={traits.alcohol} />
                            <Trait label="Табак" trait="smoking" value={traits.smoking} last />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}  xs={12}>
                    <Grid container className={classes.TraitBox} style={{ marginBottom: 48 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Параметры поиска</Typography>
                            <Trait label="Кого хотите найти" trait="search_gender" value={traits.search_gender} />
                            <Trait label="Цель поиска" trait="search_purpose" value={traits.search_purpose} last />

                            <Typography variant="h6" gutterBottom>Местоположение</Typography>
                            <Trait label={generateLocation(questions.countries, traits.country, traits.city)} last />

                            <Typography variant="h6" gutterBottom>Мой статус</Typography>
                            <Trait label="Премиум" value={traits.is_premium ? "Активен" : "Не активен"} />
                            <CancelSubscriptionDialog />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    TraitBox: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
        }
    }
}));

const mapStateToProps = ({ profile }) => ({
    traits: profile.traits,
    images: profile.images,
    questions: profile.questions
});

export default connect(mapStateToProps)(Profile);
