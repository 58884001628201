import React from 'react';
import {
    Grid,
    Container,
} from '@material-ui/core';
import PaymentWidget from '../profile/PaymentWidget';

const MobilePayment = () => {
    return (
        <div className="Home">
            <Container>
                <Grid container justify="center" style={{ height: '100vh', paddingTop: 36 }}>
                    <Grid item xs={12} sm={12} md='auto' xl='auto'>
                        <PaymentWidget inAppPayment />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default MobilePayment;
