import api from '../api';
import axios from 'axios';

import {
    ADD_ERROR,
    LOAD_PROFILE,
    LOAD_QUESTIONS,
    EDIT_PROFILE,
    UPLOAD_PROFILE_IMAGE,
    REMOVE_PROFILE_IMAGE,
    TOGGLE_SUBSCRIPTION_DIALOG,
    TOGGLE_PAYMENT_DIALOG,
    TOGGLE_PAYMENT_FORM_LOADING,
    CANCEL_SUBSCRIPTION,
} from './types';

// Load profile
export const loadProfile = () => async dispatch => {
    try {
        const [info, choice_list, images] = await Promise.all([
            api.get('/api/users/info/'),
            api.get('/api/users/choice_list/'),
            api.get('/api/users/images/')
        ]);

        dispatch({ type: LOAD_PROFILE, payload: {
            traits: info.data,
            questions: choice_list.data,
            images: images.data.images
        }});
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { loadProfile: error.response.data } });
    }
}

// Load questions
export const loadQuestions = () => async dispatch => {
    try {
        const response = await axios.get('/api/users/choice_list/');

        dispatch({ type: LOAD_QUESTIONS, payload: response.data });
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { loadProfile: error.response.data } });
    }
}

// Edit profile
export const editProfile = (formValues, setSubmitting, history) => async dispatch => {
    try {
        const response = await api.post('/api/users/update/', formValues);

        setSubmitting(false);
        dispatch({ type: EDIT_PROFILE, payload: response.data });
        history.push('/profile');
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { editProfile: error.response.data } });
    }
}

// Upload image
export const uploadImage = (event, setLoading) => async dispatch => {
    try {
        setLoading(true);
        const image = event.target.files[0];

        // Convert size from bytes to megabytes
        const size = image.size / (1024*1024);

        if (size <= 5) {
            const formData = new FormData();
            formData.append("image", image, image.name);
            const response = await api.post('/api/users/upload_image/', formData);

            setLoading(false);
            dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: response.data });
        } else {
            setLoading(false);
            dispatch({ type: ADD_ERROR, payload: { alertError: 'Максимальный размер файла: 5 MB' } });
        }

    } catch (error) {
        if (error.response) {
            setLoading(false);
            dispatch({ type: ADD_ERROR, payload: { alertError: error.response.data } });
        } else {
            setLoading(false);
            dispatch({ type: ADD_ERROR, payload: { alertError: 'Error uploading a photo' } });
        }
    }
}

// Remove image
export const removeImage = imageId => async dispatch => {
    try {
        await api.delete(`/api/users/delete_image/${imageId}/`);

        dispatch({ type: REMOVE_PROFILE_IMAGE, payload: imageId });
    } catch (error) {
        dispatch({ type: ADD_ERROR, payload: { editProfile: error.response.data } });
    }
}

// Cancel subscription
export const cancelSubscription = setLoading => async dispatch => {
    try {
        setLoading(true);
        await api.post(`/api/payments/cancel_subscription/`);

        dispatch({ type: CANCEL_SUBSCRIPTION });
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
}

// Toggle subscription dialog
export const toggleSubscriptionDialog = value => async dispatch => {
    dispatch({ type: TOGGLE_SUBSCRIPTION_DIALOG, payload: value });
}

// Toggle payment dialog
export const togglePaymentDialog = value => async dispatch => {
    dispatch({ type: TOGGLE_PAYMENT_DIALOG, payload: value });
}

// Toggle payment form loading
export const togglePaymentFormLoading = value => async dispatch => {
    dispatch({ type: TOGGLE_PAYMENT_FORM_LOADING, payload: value });
}

