import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import {
    Close as CloseIcon,
    ForumRounded as ForumRoundedIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { skipUser } from '../../actions/search';
import { newChat } from '../../actions/chat';

const SearchActions = ({ user, skipUser, newChat }) => {
    const classes = useStyles();
    return (
        <div className={classes.SearchActions}>
            <Fab component={Link} to="/chat" className={classes.Button} onClick={() => newChat(user)}>
                <ForumRoundedIcon color="secondary" />
            </Fab>
            <Fab onClick={skipUser} className={classes.Button}>
                <CloseIcon />
            </Fab>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    Button: {
        background: '#fff',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    SearchActions: {
        width: '100%',
        padding: theme.spacing(4),
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const mapStateToProps = ({ search }) => ({
    currentUser: search.users ? search.users[search.current] : null,
});

export default connect(mapStateToProps, { skipUser, newChat })(SearchActions);
