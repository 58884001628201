import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    useMediaQuery,
} from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
    ReportProblem as ReportProblemIcon
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { Select, TextField } from 'formik-material-ui';

import api from '../../api';
const ReportSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Это поле обязательно!'),
  text: Yup.string()
    .required('Это поле обязательно!'),
});

const Report = ({ fromUserId, toUserId }) => {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const classes = useStyles();
    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        try {
            await api.post('/api/users/reports/', {
                from_user: fromUserId,
                to_user: toUserId,
                reason: values.reason,
                text: values.text
            });
            setSubmitting(false);
            setSuccess(true);
            resetForm({ reason: "", text: "" });
        } catch (err) {
            console.log(err);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSuccess(false);
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <ReportProblemIcon />
            </IconButton>
            <Formik
                initialValues={{ reason: "", text: "" }}
                validationSchema={ReportSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting  }) => (
                    <Dialog
                        onClose={handleClose}
                        open={open}
                        fullScreen={!upMd}
                    >
                        <DialogTitle disableTypography  className={classes.DialogTitle}>
                            <Typography variant="h6" align="center">Пожаловаться</Typography>
                            <IconButton onClick={handleClose} className={classes.CloseButton}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        {success ? <>
                            <DialogContent
                                dividers
                                style={{
                                    width: upMd ? 448 : 'auto',
                                    height: upMd ? 268 : 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CheckCircleIcon color="primary" fontSize="inherit" style={{ margin: '0px auto 8px', fontSize: '84px', color: '#8bc34a'}} />
                                <Typography variant="h6" align="center">Жалоба отправлена</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    style={{ textTransform: 'none' }}
                                    onClick={handleClose}
                                >
                                    Закрыть
                                </Button>
                            </DialogActions>
                        </> : <>
                            <DialogContent dividers >
                                <Form style={{ width: upMd ? 400 : 'auto' }}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        error={errors.reason && touched.reason}
                                    >
                                        <InputLabel htmlFor="report-reason" variant="outlined">Причина жалобы</InputLabel>
                                        <Field
                                            component={Select}
                                            name="reason"
                                            variant="outlined"
                                            labelId="report-reason"
                                            label="Причина жалобы"
                                            required
                                            inputProps={{
                                                id: 'report-reason',
                                            }}
                                        >
                                            <MenuItem value="Спам">Спам</MenuItem>
                                            <MenuItem value="Грубое поведение">Грубое поведение</MenuItem>
                                            <MenuItem value="Нецензурные материалы">Нецензурные материалы</MenuItem>
                                            <MenuItem value="Другое">Другое</MenuItem>
                                        </Field>
                                        <FormHelperText>{errors.reason && touched.reason ? errors.reason : ""}</FormHelperText>
                                    </FormControl>
                                    <Field
                                        component={TextField}
                                        name="text"
                                        label="Опишите причину жалобы"
                                        variant="outlined"
                                        margin="normal"
                                        rows={6}
                                        rowsMax={6}
                                        error={errors.text && touched.text}
                                        helperText={errors.text && touched.text ? errors.text : ""}
                                        required
                                        multiline
                                        fullWidth
                                    />
                                </Form>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    style={{ textTransform: 'none' }}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    Отправить
                                </Button>
                            </DialogActions>
                        </>}
                    </Dialog>
                )}
            </Formik>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    DialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    CloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

const mapStateToProps = ({ profile }) => ({
    fromUserId: profile.traits.id
});

export default connect(mapStateToProps)(Report);
